import { MessageTypeEnum } from '@shared/enums';
import { escapeAndReplaceDefinedCharacters, getSMSMessageLength, getSMSMessageSegmentCount, isGsmMessage } from '@shared/services';
import { Checkbox, DateTimePicker, Error, Info, TextAreaTags, TextInput } from '@Wonder-Cave/ui';
import { useCustomDataPoints } from 'apps/a2p-portal/src/hooks/useCustomDataPoints';
import useDomains from 'apps/a2p-portal/src/hooks/useDomains';
import { FastField, Field } from 'formik';
import moment from 'moment';
import NewDomainsDropdown from '../../shared/Form/Dropdowns/NewDomainsDropdown';
import Tags from '../../shared/Form/Tags';
import { ICampaignForm } from '../types';

interface IBuildProps {
  values?: ICampaignForm;
  errors?: any;
  touched?: any;
  setFieldValue?: (field: string, value: any) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  onSubmit: (formikProps) => void;
  contactCount: number;
  submissionError?: string;
}

const Build = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  contactCount,
  onSubmit,
  submissionError
}: IBuildProps) => {
  const [{ data, loading }] = useCustomDataPoints({ isActive: true, personalized: true, take: 100 });
  const tags = data?.records ?? [];
  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();

  let errorMessages: string[] = [];
  let warningMessage: string[] = [];
  if (touched.message) {
    if (!isGsmMessage(values?.message!)) {
      warningMessage.push('Your message contains a special character which might result in greater than expected number of segments');
    }
    if (values?.messageType === MessageTypeEnum.SMS && getSMSMessageSegmentCount(
      values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domain}/aaaaaa`)!) > 1) {
      warningMessage.push('Your message will contain multiple segments');
    }
    if (errors.message) {
      errorMessages.push(errors.message);
    }
  }

  const errorMsg = errorMessages.join('; ') ?? '';

  return (
    <>
      <div className="flex flex-wrap space-y-8">
        <div className="flex justify-between w-full">
          <h2 className="mb-2 text-black basis-full">Create Message</h2>
        </div>
        <Field
          component={TextAreaTags}
          id="message"
          name="message"
          label="Message"
          placeholder="Enter your message here..."
          className="flex flex-row-reverse items-start justify-end w-full build-section gap-x-4"
          wrapperClassName="shrink-0 basis-1/2"
          value={values?.message!}
          error={errorMsg}
          warning={warningMessage}
          onChange={(value: string) => {
            if (value.length > 0) {
              setFieldTouched!('message', true);
              value = escapeAndReplaceDefinedCharacters(value);
            }
            setFieldValue!('message', value);
          }}
          onBlur={handleBlur}
          description={
            <div className="flex justify-between body-text-small text-medium-gray">
              <p>
                Segments:{' '}
                {values?.messageType === MessageTypeEnum.MMS
                  ? 1
                  : getSMSMessageSegmentCount(
                    values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domain}/aaaaaa`)!
                  )}{' '}
              </p>
              <p>
                Characters:{' '}
                {getSMSMessageLength(values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domain}/aaaaaa`)!)}/
                {values?.messageType === MessageTypeEnum.MMS ? 1600 : 1000}
              </p>
            </div>
          }
          contactListIds={[]}
          renderTags={(onTagClick) => <Tags tags={data?.records ?? []} onClick={onTagClick} />}
        />
        <div className="flex flex-wrap items-start w-full border-b-2 build-section border-light-gray">
          <div style={{ flexBasis: '100%' }} className="mb-6">
            <Checkbox
              id="clickTrackingEnabled"
              name="clickTrackingEnabled"
              label="Enable Click Tracking"
              description="Generate unique links for each message by including {link} in your message"
              checked={values?.clickTrackingEnabled}
              onChange={handleChange}
            />
          </div>

          {values?.clickTrackingEnabled && (
            <div className='flex w-full'>
              <div className="mb-6 grow">
                <FastField
                  component={TextInput}
                  id="url"
                  name="url"
                  label="DESTINATION URL"
                  value={values?.url}
                  error={touched.url ? errors.url : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="pl-4 mb-6 grow">
                <NewDomainsDropdown
                  value={values?.domainId ?? ''}
                  options={domainsData?.records}
                  onChange={(selectedValue) => {
                    setFieldValue!('domainId', selectedValue?.value);
                    // Need label for message text
                    setFieldValue!('domain', selectedValue?.label);
                  }}
                  onBlur={() => {
                    setFieldTouched!('domainId', true);
                  }}
                  showError={touched.domain}
                  errorMessage={errors.domain}
                  disabled={(domainsData?.records?.length ?? 0) <= 0}
                />
                {(domainsData?.records?.length ?? 0) <= 0 && <Error text={"You don't have any active domains for click tracking."} />}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-wrap items-start w-full">
          <div className="flex flex-row justify-between w-full">

            <div className='flex flex-col basis-full'>
              <h2 className="mb-6 text-black ">Schedule your message</h2>
              <div className='flex flex-row'>
                <h4 className="mb-6 text-dark-gray">When would you like to send your message?</h4>
                <Info className='ml-2 mt-0.5' text='Daily sending window: 8 AM to 9 PM' />
              </div>
            </div>
          </div>
          <div className="flex w-full mt-6 mb-6 gap-x-4">
            <FastField
              component={DateTimePicker}
              full
              label="START DATE & TIME"
              name="startsAt"
              value={new Date(values?.startsAt!)}
              error={touched.startsAt && errors.startsAt ? (errors.startsAt as string) : ''}
              onChange={(newValue: Date) => {
                setFieldTouched!('startsAt', true);
                setFieldValue!('startsAt', newValue);
              }}
            />
            {/* Note: Currently a UI bug where min date doesn't get updated since it's a fast field. Without being a fast field the page gets laggy because the text area can't be a fast field  */}

            <div className='w-full'>
              <label htmlFor="endsAt">
                <div className="flex items-center w-full mb-5">
                  <h5 className='shrink-0 text-medium-gray'>END DATE & TIME</h5>
                  <Info className="w-0 ml-[0.5rem] z-10" width={165} bubbleWrapperClassName='w-[8rem]' text="You can select an end date up to 2 days into the future." />
                </div>
              </label>

              <FastField
                component={DateTimePicker}
                full
                id="datePicker_right"
                name="endsAt"
                value={new Date(values?.endsAt!)}
                disabled
                error={touched.endsAt && errors.endsAt ? (errors.endsAt as string) : ''}
                onChange={(newValue: Date) => {
                  setFieldTouched!('endsAt', true);
                  setFieldValue!('endsAt', newValue);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-start justify-between w-full">
        <h3 className="mb-4 text-wc-blue">
          {`${contactCount > 0 ? contactCount.toLocaleString() + ' m' : 'M'}essages scheduled to send ${`at ${moment(values?.startsAt).format('L LT')}`}`}
        </h3>
        {submissionError && <h3 className='mb-4 text-red-600'>{submissionError}</h3>}
      </div>
    </>
  );
};

export default Build;
