import { A2P_ORG_ID } from '@shared/models';
import { useMembership } from 'apps/a2p-portal/src/contexts/MembershipContext';
import { orderStringAscending } from 'apps/a2p-portal/src/providers/utility.provider';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IOrganizationDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const OrganizationDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IOrganizationDropdownProps): JSX.Element => {
  const { organizations } = useMembership();

  const options = organizations
    ?.filter(org => !!org && org?.id !== A2P_ORG_ID)
    ?.map((organization) => ({
      label: organization.display_name,
      value: organization.name,
      additionalData: organization.display_name,
    }))
    ?.filter(org => !!org?.label)
    ?.sort(orderStringAscending('label')) as IDropdownValue[]
    ?? [];

  return (
    <Autocomplete
      label="Organization"
      value={options.find((o) => o.value === value?.value)}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default OrganizationDropdown;
