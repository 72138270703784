import { Button, ButtonVariantEnum } from '@Wonder-Cave/ui';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { IRecurringCampaignForm } from './types';

interface IRecurringCampaignsCreateHeaderProps {
  formData: IRecurringCampaignForm;
  stepNumber: number;
  formikProps: FormikProps<IRecurringCampaignForm>;
  saveDraft: (formikProps: FormikProps<IRecurringCampaignForm>) => Promise<AxiosResponse | undefined>;
}

const RecurringCampaignsCreateHeader = ({ formData, stepNumber, formikProps, saveDraft }: IRecurringCampaignsCreateHeaderProps) => {
  const navigate = useNavigate();
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);

  const handleSave = async (formikProps) => {
    try {
      setLoading(true);
      const response = await saveDraft(formikProps);
      if (response) {
        addNotification({ header: 'Draft campaign saved successfully', type: NotificationType.SUCCESS });
        navigate('/recurring-campaigns');
      }
    } catch (e: any) {
      console.error(e);

      const status = e.response?.status;
      const message = (status >= 400 && status < 500)
        ? (e.response?.data?.errors?.[0] ?? e.response?.data?.message ?? e.response?.data ?? e.message)
        : 'Internal Server Error. Failed to save campaign.';

      const lowerMessage = message.toLowerCase();
      if (stepNumber === 0 && lowerMessage.includes('a campaign with the name') && lowerMessage.includes('already exists')) {
        formikProps?.setTouched({
          name: true
        });
        await formikProps.setFielError('name', message);
      }
    } finally {
      setLoading(false);
    }
  };

  return <div className="flex items-center justify-between w-full mb-8 px-28">
    <h1>{formData?.name && stepNumber === 2 ? formData?.name : 'Create Recurring Campaign'}</h1>
    <Button isLoading={loading} disabled={loading} type="button" onClick={() => handleSave(formikProps)} variant={ButtonVariantEnum.TERTIARY}>
      SAVE DRAFT & EXIT
    </Button>
  </div>;
};

export default RecurringCampaignsCreateHeader;