import { MessageTypeEnum } from '@shared/enums';
import { escapeAndReplaceDefinedCharacters, getSMSMessageLength, getSMSMessageSegmentCount, isGsmMessage } from '@shared/services';
import { TextAreaTags } from '@Wonder-Cave/ui';
import 'draft-js/dist/Draft.css';
import { Field } from 'formik';
import BuildTags from '../../../shared/Form/BuildTags';
import { IBuildProps } from './types';

const BuildMessage = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleBlur,
  readOnly,
  customDataPoints,
}: IBuildProps) => {
  const gsmErrorMessage = touched?.message
    ? errors?.message
      ? errors?.message
      : !isGsmMessage(values?.message!)
        ? 'Message contains non-GSM characters'
        : ''
    : '';

  const onMessageChange = (value: string) => {
    if (value.length > 0) {
      setFieldTouched!('message', true);
      value = escapeAndReplaceDefinedCharacters(value);
    }

    setFieldValue!('message', value);
    if (!!values) {
      values.message = value;
    }
  };

  const segmentCount = values?.messageType === MessageTypeEnum.MMS
    ? 1
    : getSMSMessageSegmentCount(values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domainId}/aaaaaa`)!);

  return (
    <div className='flex flex-row'>
      <div className='flex flex-col flex-grow'>
        <Field
          component={TextAreaTags}
          id='message'
          name='message'
          label='Message'
          placeholder='Enter your message here...'
          className='flex flex-row-reverse items-start justify-end w-full border-0 build-section gap-x-4'
          wrapperClassName='w-full'
          value={values?.message!}
          error={gsmErrorMessage}
          onChange={value => onMessageChange(value)}
          onBlur={handleBlur}
          disabled={!!readOnly}
          description={
            <div className='flex justify-between body-text-small text-medium-gray'>
              <p>
                Segments:{' '}
                {segmentCount}{' '}
              </p>
              <p>
                Characters:{' '}
                {getSMSMessageLength(values?.message?.toLowerCase()?.replace(/{{link}}/gi, `${values?.domainId}/aaaaaa`)!)}/
                {values?.messageType === MessageTypeEnum.MMS ? 1600 : 1000}
              </p>
            </div>
          }
          renderTags={(onTagClick) => (
            <div className='min-w-[50%] max-w-[50%]'>
              <BuildTags
                tags={customDataPoints}
                onClick={onTagClick}
              ></BuildTags>
            </div>
          )}
          contactListIds={[]}
        />
      </div>
    </div>
  );
};

export default BuildMessage;
