import { useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import NotificationsRegion from './components/NotificationsRegion';
import { IDropdownValue } from './components/shared/Form/Dropdown';
import LoadingIndicator from './components/shared/LoadingIndicator';
import { LeftNav } from './components/shared/Nav/LeftNav';
import Shimmer, { ShimmerType } from './components/shared/Shimmer';
import { ClientContextProvider, IClientContext } from './contexts/ClientContext';
import { NotificationsProvider } from './contexts/NotificationContext';
import { SidebarContextProvider } from './contexts/SidebarContext';
import { SocketContextProvider, socketManager } from './contexts/SocketContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import useClientsManual from './hooks/useClientsManual';
import { Auth0FrontendConfig } from './providers/auth0-data.provider';

interface IAppProps {
  configured: boolean;
  children?: JSX.Element;
  auth0Config: Auth0FrontendConfig;
}

const AppWrapper = ({ configured, children, auth0Config }: IAppProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [activeUser, setActiveUser] = useState({});
  const [devMode, setDevMode] = useState(false);
  const [client, setClient] = useState<IDropdownValue>();
  const sidebarContextValue = { devMode, sidebarOpen, activeUser, setDevMode, setSidebarOpen, setActiveUser };
  const { isLoading, isAuthenticated: isLoggedIn, logout: auth0Logout, user: auth0User } = useAuth0UserData();
  const [{ data: clientData, loading: clientLoading, error: clientError }, searchClients] = useClientsManual();
  const location = useLocation();

  const isFullHeight = location.pathname.startsWith('/journeys') ? 'h-full' : '';

  const clientContextValue: IClientContext = {
    clients: clientData,
    loading: clientLoading,
    setClient,
    selectedClient: client,
    selectedClientId: client?.value,
    permissionsLoaded: true,
    refreshClients: searchClients,
  };

  const theme = localStorage.getItem('a2p-theme');
  if (theme === 'dark') {
    // This enables dark mode if the user has it set as their OS default || (!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }

  if (!isLoading && !isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
      />
    );
  }

  if (!configured || isLoading) {
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }
  return (
    <ClientContextProvider value={clientContextValue}>
      <SocketContextProvider value={socketManager}>
        <SidebarContextProvider value={sidebarContextValue}>
          <NotificationsProvider>
            <div
              className={`flex h-screen overflow-hidden bg-gray-100 text-black dark:bg-slate-900 dark:text-slate-400`}
            >
              <LeftNav auth0Config={auth0Config} />
              <div className="flex flex-col flex-1 w-0 overflow-hidden">
                <NotificationsRegion />
                <main className={`relative flex-1 pt-6 overflow-y-auto focus:outline-none `}>
                  <div className={`px-4 mb-4 sm:px-6 md:px-8 ${isFullHeight}`}>
                    {!!client ? (
                      <Outlet />
                    ) : (
                      <div className="flex flex-wrap gap-5">
                        <Shimmer type={ShimmerType.HEADER} />
                        <Shimmer type={ShimmerType.TABLE} />
                      </div>
                    )}
                  </div>
                </main>
              </div>
            </div>
          </NotificationsProvider>
        </SidebarContextProvider>
      </SocketContextProvider>
    </ClientContextProvider>
  );
};

export default AppWrapper;
