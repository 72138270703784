import { SocketPayload } from '@shared/models';
import React, { createContext } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Auth0FrontendConfig } from '../providers/auth0-data.provider';
import { SocketManager } from '../providers/socket.provider';

let socketManager: SocketManager;
let SocketContext: React.Context<SocketManager | null>;
let SocketContextProvider: React.Provider<SocketManager | null>;
let SocketContextConsumer: React.Consumer<SocketManager | null>;

function initializeWebSocket(config: Auth0FrontendConfig) {
  if (socketManager) {
    return;
  }

  const socket = new ReconnectingWebSocket(config.websocketUrl);

  socket.onopen = (event: any) => {
    console.log('Socket connected successfully', { event });
  };

  socket.onmessage = (event: MessageEvent<any>) => {
    const payload: SocketPayload<any> = JSON.parse(event.data);
    console.log('Socket Event', payload);

    const subscriptions = socketManager.subscriptions.get(payload.name) ?? [];
    console.log('# of Subscriptions', subscriptions?.length ?? 0);

    subscriptions.forEach((sub) => sub(payload));
  };

  socketManager = new SocketManager(socket);
  SocketContext = createContext<SocketManager | null>(null);
  SocketContextProvider = SocketContext.Provider;
  SocketContextConsumer = SocketContext.Consumer;
}

export { initializeWebSocket, SocketContext, SocketContextConsumer, SocketContextProvider, socketManager };

