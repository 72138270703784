import { Checkbox, Error, TextInput } from '@Wonder-Cave/ui';
import 'draft-js/dist/Draft.css';
import { FastField } from 'formik';
import NewDomainsDropdown from '../../../shared/Form/Dropdowns/NewDomainsDropdown';
import { IBuildProps } from './types';

const BuildLinkTracking = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  domains,
  readOnly,
}: IBuildProps) => {
  return (
    <div className='flex flex-row'>
      <div className='basis-[50%] select-none'>
        <Checkbox
          id="clickTrackingEnabled"
          name="clickTrackingEnabled"
          label="Enable Click Tracking"
          description="Generate unique links by adding a link in your message"
          checked={values?.clickTrackingEnabled}
          onChange={handleChange}
          disabled={!!readOnly}
        />
      </div>

      {values?.clickTrackingEnabled && (
        <div className='basis-[50%] flex flex-col'>
          <div className='mb-4'>
            <FastField
              component={TextInput}
              id="url"
              name="url"
              label="DESTINATION URL"
              value={values?.url}
              error={touched!.url ? errors!.url : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!!readOnly}
            />
          </div>

          <div className='mb-4'>
            <NewDomainsDropdown
              value={values?.domainId ?? ''}
              options={domains}
              onChange={(selectedValue) => {
                setFieldValue!('domainId', selectedValue?.value);
                // Need label for message text
                setFieldValue!('domain', selectedValue?.label);
              }}
              onBlur={() => {
                setFieldTouched!('domainId', true);
              }}
              showError={touched!.domainId}
              errorMessage={errors!.domainId}
              disabled={!!readOnly || (domains?.length ?? 0) <= 0}
            />
            {(domains?.length ?? 0) <= 0 && <Error color={"text-flame-orange"} text={"You don't have any active domains for click tracking."} />}
          </div>

        </div>
      )}
    </div>
  );
};

export default BuildLinkTracking;
