import { Auth0GetUserDetailsRequest, Auth0Role, Auth0User, Auth0UserDetails, FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter, ISearchRequest } from '@shared/models';
import { extendObject } from '@shared/services';
import { useContext, useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import { SidebarContext } from '../../contexts/SidebarContext';
import useAssignedClients from '../../hooks/useAssignedClients';
import { useAuth0UserData } from '../../hooks/useAuth0Custom';
import useUsers from '../../hooks/useUsers';
import { createUser, getUserDetails, getUserRole, updateUser } from '../../providers/auth0.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Roles from '../shared/Roles';
import Search from '../shared/Table/Search';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import UploadUsersModal from './UploadUsersModal';
import UserDetailsPanel from './UserDetailsPanel';
import { IUserForm, defaultUsersTableOptions, getUsersColumns, usersFilterDropdownOptions } from './types';

const Users = () => {
  const { setActiveUser } = useContext(SidebarContext);
  const { addNotification } = useNotifications();

  const [searchText, setSearchText] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [userCreating, setUserCreating] = useState(false);
  const [userDeleting, setUserDeleting] = useState(false);
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Auth0UserDetails>();
  const [tableOptions, setTableOptions] = useState(defaultUsersTableOptions);
  const [deletedUserIds, setDeletedUserIds] = useState<string[]>([]);

  const [{ data: userData, loading: userLoading, error: userError }, searchUsers] = useUsers(tableOptions);
  const [{ data: assignedClientData, loading: clientsLoading, error }, refetchAssignedClients] = useAssignedClients({ userId: selectedItem?.user_id });
  const { user: auth0User } = useAuth0UserData();

  useEffect(() => {
    const searchText = tableOptions?.filters?.find((filter) => filter?.fieldName === 'name')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const usersTotalCount = userData?.totalCount;

  const users = userData?.records
    ?.map(user => extendObject(user, { role: getUserRole(auth0User.organization_name, user) }))
    ?.filter(user => !deletedUserIds?.includes(user?.user_id))
    ?? [];

  const columns = getUsersColumns(
    { email: (item: Auth0User) => openDetailsPanel(item) },
    { name: (item: Auth0User) => <>{(item.given_name ?? '') + ' ' + (item.family_name ?? '')}</> }
  );

  const filterColumns: IColumn[] = columns.filter((col) => ['email', 'type'].includes(col.fieldName));

  const customFilters = [
    {
      customName: 'First Name',
      fieldName: 'given_name',
      dataType: FilterDataTypeEnum.STRING,
      operator: FilterOperatorEnum.CONTAINS,
    },
    {
      customName: 'Last Name',
      fieldName: 'family_name',
      dataType: FilterDataTypeEnum.STRING,
      operator: FilterOperatorEnum.CONTAINS,
    }
  ];

  const handleRefetch = async () => {
    try {
      await searchUsers();
    } catch (error) { }
  };

  const openDetailsPanel = async (item?: Auth0User) => {
    if (item) {
      const request: Auth0GetUserDetailsRequest = { user_id: item.user_id ?? '' };
      const user = await getUserDetails(request);
      await refetchAssignedClients({
        params: {
          userId: item?.user_id
        }
      });
      setSelectedItem(user);
    }

    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const handleSearch = (overrideText?: string) => {
    let emailFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'email',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      emailFilter.value = overrideText;
    }

    const options = { ...defaultUsersTableOptions };
    const filters = tableOptions.filters.filter((filter) => filter.fieldName !== 'email');
    options.filters = emailFilter.value ? [...filters, emailFilter] : [...filters];

    setTableOptions(options);
  };

  const handleDuplicateEmail = (error: unknown) => {
    if (error instanceof Error && error.message.includes('409')) {
      closeDetailsPanel();
      addNotification({ content: 'Email must be unique.', type: NotificationType.FAILURE });
    }
  };

  const upsertUser = async (form: IUserForm, user?: Auth0UserDetails) => {
    const successMessage: string = user ? 'User updated successfully' : 'User invited successfully';
    const failMessage: string = user
      ? 'An error occurred while updating a user'
      : 'An error occurred while creating a user';

    try {
      setUserCreating(true);
      user ? await updateUser(user, form) : await createUser(form);
      addNotification({ header: successMessage });
      setTableOptions({ ...defaultUsersTableOptions });

      if (user?.user_id === auth0User?.user_id) {
        setActiveUser({ firstName: form.firstName, lastName: form.lastName });
      }

      closeDetailsPanel();
    } catch (error) {
      handleDuplicateEmail(error);
      console.error(failMessage);
    } finally {
      setUserCreating(false);
    }
  };

  const removeUser = async (item: Auth0UserDetails) => {
    try {
      setUserDeleting(true);
      await axiosPost('/auth0-remove-user-membership', { user_id: item.user_id });
      closeDetailsPanel();
      addNotification({
        content: 'Removed User.',
        type: NotificationType.SUCCESS,
      });
      setDeletedUserIds([...deletedUserIds, item.user_id]);
      handleRefetch();
    } catch (e) {
      console.error(e);
    } finally {
      setUserDeleting(false);
    }
  };

  return (
    <>
      <h2>Users</h2>

      <div className="flex justify-between pb-2">
        <Search
          id="usersEmailSearch"
          name="usersEmailSearch"
          placeholder="Email"
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          tableOptions={defaultUsersTableOptions}
        />

        <div className="flex ml-2 space-x-2">
          <Roles roles={[Auth0Role.A2P_ADMIN]}>
            <Button variant={ButtonVariantEnum.SECONDARY} className="self-end" onClick={() => openDetailsPanel()}>
              Invite User
            </Button>
          </Roles>
        </div>
      </div>

      <Table
        columns={columns}
        items={users}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        shimmer
        paginate
        sort={true}
        count={usersTotalCount}
        loading={userLoading}
        booleanLabels={[{ fieldName: 'active', displayValues: { trueLabel: 'Active', falseLabel: 'Inactive' } }]}
        filterColumns={filterColumns}
        customFilters={customFilters}
        filterDropdownOptions={usersFilterDropdownOptions}
        onRefresh={handleRefetch}
      />

      <UserDetailsPanel
        show={showDetailsPanel}
        loading={userCreating}
        item={selectedItem}
        assignedClients={!!selectedItem ? assignedClientData : []}
        deleteLoading={userDeleting}
        handleDelete={removeUser}
        closePanel={closeDetailsPanel}
        onSubmit={async (formData: IUserForm) => await upsertUser(formData, selectedItem)}
      />

      <UploadUsersModal show={showUpload} setShow={setShowUpload} onUpload={handleRefetch} />
    </>
  );
};

export default Users;
