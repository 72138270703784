import { RecurringCampaignCooldownPeriodEnum, RecurringCampaignFrequencyEnum } from '..';

export function formatFrequencyFields(count: number, period: string) {
  if (count === 1) {
    return `${count} ${period.toLowerCase().replace('s', '')}`;
  }
  return `${count} ${period.toLowerCase()}`;
}

export function convertFrequencyFormat(period: RecurringCampaignFrequencyEnum | RecurringCampaignCooldownPeriodEnum) {
  switch (period) {
    case 'DAYS': {
      return 'daily';
    }
    case 'WEEKS': {
      return 'weekly';
    }
    case 'MONTHS': {
      return 'monthly';
    }
    default: {
      return '';
    }
  }
}