import { CheckUniqueCampaignNameResponse, ICustomDataPointEntity, IHttpResponse } from '@shared/models';
import { Button } from '@Wonder-Cave/ui';
import { FormikErrors, FormikProps } from 'formik';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosPost } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { ICampaignForm } from './types';

interface ICampaignsCreateFooter {
  customDataPoints: ICustomDataPointEntity[];
  loading: boolean;
  sending: boolean;
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  formikProps: FormikProps<ICampaignForm>;
  setFormErrors: (errors: FormikErrors<ICampaignForm>) => void;
  setFormData: React.Dispatch<React.SetStateAction<ICampaignForm>>;
  formErrors: FormikErrors<ICampaignForm>;
}

const CampaignsCreateFooter = ({ customDataPoints, stepNumber, formikProps, loading, sending, formErrors, setFormErrors, setFormData, setStepNumber }: ICampaignsCreateFooter) => {
  const { addNotification } = useNotifications();
  const { selectedClientId } = useClientContext();
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const [nextLoading, setNextLoading] = useState(false);

  const submitPlan = async (formikProps) => {
    try {
      // Clear previous form errors
      setFormErrors({});

      setNextLoading(true);

      // Touch all required fields
      let errors = await formikProps.setTouched({
        isMMSVideo: true,
        mediaUrl: true,
        showSuppressions: true,
        name: true,
        messageType: true,
        externalId: true,
        mediaFile: true,
        mediaName: true,
        audiences: true,
        suppressions: true,
        frontendInlineSuppression: true,
        systemNumberId: true
      });

      // Validate name is unique per tenant
      if (!id) {
        const name = formikProps.values.name;
        const checkNameResp = await axiosPost<IHttpResponse<CheckUniqueCampaignNameResponse>>('/campaigns/check-unique-name', { name: name });
        console.log(checkNameResp);
        if (!checkNameResp?.data?.isValid) {
          const invalidNameMsg = `A campaign with the name "${name}" already exists`;

          setFormErrors({
            ...formErrors,
            name: invalidNameMsg
          });

          errors.name = invalidNameMsg;
        }
      }

      if (Object.keys(errors).length <= 0) {
        setFormData(formikProps.values);
        setStepNumber((prevState) => prevState + 1);
      }
    } finally {
      setNextLoading(false);
    }
  };

  const submitBuild = async (formikProps) => {
    // setNextLoading(true);
    // try {
    //   const touched = {};
    //   Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
    //   formikProps.setTouched(touched);
    //   let errors = await formikProps.setTouched({
    //     url: true,
    //     domainId: true,
    //     clickTrackingEnabled: true,
    //     hasNoEndDate: true,
    //     message: true,
    //     startsAt: true,
    //     endsAt: true,
    //     frequencyCount: true,
    //     sendingStartsAt: true,
    //     frequency: true,
    //     cooldownType: true,
    //     cooldownPeriod: true,
    //     cooldownPeriodCount: true
    //   });
    //   if (Object.keys(errors).length <= 0) {
    //     setFormData({ ...formikProps.values });
    //     setStepNumber((prevState) => prevState + 1);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setNextLoading(false);
    // }
  };

  const submitPreview = async (formikProps) => {
    // try {
    //   setNextLoading(true);
    //   const recurringCampaignId = id ?? formikProps?.values?.id;

    //   const request: ICreateRecurringCampaignsRequest = {
    //     ...formikProps.values,
    //     clientId: selectedClientId,
    //     hasNoEndDate: formikProps.values.hasNoEndDate,
    //     status: RecurringCampaignStatusEnum.ACTIVE, // submission of preview is saving and executing campaign
    //     audienceIds: formikProps.values.audiences.map(a => a.value),
    //     suppressionIds: formikProps.values.suppressions.map(s => s.value),
    //     inlineSuppressionFrontendFilter: formikProps.values?.frontendInlineSuppression ?? null,
    //     inlineSuppressionBackendFilter: formikProps.values?.frontendInlineSuppression?.rules?.length >= 1
    //       ? formatBackendMongoDBQuery(formikProps.values?.frontendInlineSuppression, customDataPoints ?? [])
    //       : null,

    //     sendingStartsAt: formikProps.values?.sendingStartsAt,
    //     sendingEndsAt: formikProps.values?.sendingEndsAt,
    //     startsAt: formikProps.values?.startsAt,
    //     endsAt: formikProps.values?.endsAt,

    //     systemNumberId: formikProps.values.systemNumberId.value
    //   };

    //   let response: AxiosResponse<IHttpResponse<object>>;

    //   if (recurringCampaignId) {
    //     response = await axiosPut(`/recurring-campaigns/${recurringCampaignId}`, request);
    //   } else {
    //     response = await axiosPost(`/recurring-campaigns/create`, request);
    //   }

    //   if (response?.status === 200) {
    //     addNotification({ header: 'Recurring Campaign saved successfully.', type: NotificationType.SUCCESS });
    //     navigate('/recurring-campaigns');
    //   }
    //   else {
    //     addNotification({ header: 'Recurring Campaign failed to save.', type: NotificationType.FAILURE });
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setNextLoading(false);
    // }
  };

  const submitForm = (stepNumber: number, formikProps: any) => {
    switch (stepNumber) {
      case 0:
        submitPlan(formikProps);
        break;
      case 1:
        submitBuild(formikProps);
        break;
      case 2:
        submitPreview(formikProps);
        break;
      default:
        break;
    }
  };

  return <Button type="button" isLoading={nextLoading} onClick={() => submitForm(stepNumber, formikProps)} disabled={loading || sending}>
    {stepNumber === 2 ? 'SAVE & EXECUTE' : 'NEXT'}
  </Button>;
};

export default CampaignsCreateFooter;