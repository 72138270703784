import { CheckCircleIcon, ClockIcon, MinusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { CreateCampaignRequest, ICampaignDetails, IHttpResponse, ISearchRequest, UpdateCampaignRequest } from '@shared/models';
import { waitSeconds } from '@shared/services';
import { CreateCampaignErrorName } from 'apps/a2p-backend/models/campaigns/campaigns-create-error';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosDelete, axiosGet, axiosPost } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { useNotifications } from '../../contexts/NotificationContext';
import useCampaigns from '../../hooks/useCampaigns';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import { Button } from '../shared/Buttons/Button';
import { Table } from '../shared/Table/Table';
import CampaignDeliveredProgressBar from './CampaignDeliveredProgressBar';
import CampaignDetailsPanel from './CampaignDetailsPanel';
import { ICampaignForm, defaultCampaignsTableOptions, getClonedItem, getColumns } from './types';

const Campaigns = () => {
  const { addNotification } = useNotifications();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [campaignCreating, setCampaignCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cloneLoading, setCloneLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICampaignDetails>();
  const { selectedClientId } = useClientContext();
  const navigate = useNavigate();

  const [{ data: customDataPointsResponse }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsResponse?.records ?? [];

  const [tableOptions, setTableOptions] = useState({
    ...defaultCampaignsTableOptions,
  } as ISearchRequest);

  const [{ data: campaignData, loading: campaignLoading }, refetch] = useCampaigns({
    ...tableOptions,
    clientId: selectedClientId,
  });

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
    setCampaignCreating(false);
  };

  const onCampaignFailed = (message: string) => {
    setErrorMessage(message);
    setCampaignCreating(false);
  };

  const hasInlineSuppression = (values: ICampaignForm): boolean => {
    return (values?.frontendInlineSuppression?.rules?.length ?? 0) > 0;
  };

  const handleSubmit = async (formData: ICampaignForm) => {
    setCampaignCreating(true);
    // Create campaign
    try {

      const request: CreateCampaignRequest = {
        name: formData.name,
        clientId: selectedClientId ?? '',
        messageType: formData.messageType!,
        message: formData.message,
        startsAt: formData.startsAt.toISOString(),
        endsAt: formData.endsAt.toISOString(),
        externalId: formData.externalId,
        mediaUrl: formData.mediaUrl ?? '',
        mediaName: formData.mediaName ?? '',
        isMMSVideo: formData.isMMSVideo ?? false,
        sendTestMessages: formData?.sendTestMessages ?? false,
        clickTrackingEnabled: formData.clickTrackingEnabled,
        url: formData.clickTrackingEnabled ? formData.url : '',
        domain: formData.clickTrackingEnabled ? formData.domain : '',
        audienceIds: formData.audiences.map((a) => a.value),
        suppressionIds: formData.suppressions.map((s) => s.value),
        inlineSuppressionFrontendFilter: hasInlineSuppression(formData) ? formData?.frontendInlineSuppression : null,
        inlineSuppressionBackendFilter: hasInlineSuppression(formData)
          ? formatBackendMongoDBQuery(formData?.frontendInlineSuppression, customDataPoints)
          : null,
        systemNumberId: formData.systemNumberId.value

      };

      const response = await axiosPost('/campaigns', request);

      if (response?.status === 200) {
        await waitSeconds(2);
        closeDetailsPanel();
        refetch();
      }

    } catch (error) {
      console.error(error);

      switch (error) {
        case CreateCampaignErrorName.NO_CONTACTS_FOUND:
          onCampaignFailed('No Contacts found for the selected Audience(s) and Suppression(s)');
          break;
        default:
          onCampaignFailed('An error occurred while creating the campaign');
          break;
      }

    } finally {
      setCampaignCreating(false);
    }
  };

  const handleCampaignDelete = async (): Promise<void> => {
    if (!selectedItem?.id) {
      return;
    }

    setDeleteLoading(true);

    try {
      await axiosDelete(`/campaigns/${selectedItem.id}`);
      closeDetailsPanel();
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.toString());
    } finally {
      setDeleteLoading(false);
      await refetch();
    }
  };

  const handleCampaignActiveToggle = async (): Promise<void> => {
    if (!selectedItem?.id) {
      return;
    }

    setUpdateLoading(true);

    try {
      const request: UpdateCampaignRequest = { isActive: !selectedItem?.isActive };
      await axiosPost(`/campaigns/${selectedItem.id}`, request);

      await refetch();

      closeDetailsPanel();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateLoading(false);
      refetch();
    }
  };

  const handleClone = async (): Promise<void> => {
    if (!selectedItem?.id) {
      return;
    }

    try {
      setCloneLoading(true);
      const clone = await getClonedItem(selectedItem);
      closeDetailsPanel();
      setSelectedItem(clone);
      await new Promise(response => setTimeout(response, secondsToMilliseconds(1)));
      openDetailsPanel();
    } catch (error) {
      console.error(error);
    } finally {
      setCloneLoading(false);
    }
  };


  const openDetailsPanel = async (item?: ICampaignDetails) => {
    if (!!item) {
      const {
        data: campaign,
      } = await axiosGet<IHttpResponse<ICampaignDetails>>(`/campaigns/${item.id}`);


      setSelectedItem({
        ...campaign,
        startsAt: new Date(campaign.startsAt as any),
        endsAt: new Date(campaign.endsAt as any),
      });
    }

    setShowDetailsPanel(true);
  };

  const columns = getColumns(
    {
      name: (item: ICampaignDetails) => {
        //TODO: put back in whenever campaign list is updated
        // if (item.status !== CampaignStatusEnum.DRAFT) {
        //   navigate(`/campaigns/${item.id}`);
        // } else {
        openDetailsPanel(item);
        // }
      },
    },
    {
      deliveredProgress: (item: ICampaignDetails) => <CampaignDeliveredProgressBar campaign={item} />,
      healthCheck: (item: ICampaignDetails) =>
        item.sendTestMessages ? (
          item.totalTestMessagesFailed > 0 ? (
            <span title="One or more test messages failed to deliver" className="select-none">
              <XCircleIcon className="w-5 h-5 text-red-400" />
            </span>
          ) : item.totalTestMessagesSent === item.totalTestMessagesDelivered && item.totalTestMessagesSent !== 0 ? (
            <span title="All test messages delivered" className="select-none">
              <CheckCircleIcon className="w-5 h-5 text-emerald-400" />
            </span>
          ) : (
            <span title="Not all test messages are delivered yet" className="select-none">
              <ClockIcon className="w-5 h-5 text-sky-400" />
            </span>
          )
        ) : (
          <span title="No health check performed" className="select-none">
            <MinusCircleIcon className="w-5 h-5 text-gray-400" />
          </span>
        ),
    }
  );

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  return (
    <>
      <h2 className="header-page">
        Campaigns
        <Button onClick={() => openDetailsPanel()}>Add Campaign</Button>
      </h2>
      <Table
        columns={columns}
        items={campaignData?.records}
        count={campaignData?.totalCount}
        loading={campaignLoading}
        skipEnumFormattingColumns={['messageType']}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        paginate
        onRefresh={handleRefetch}
      />
      <CampaignDetailsPanel
        handleSubmit={handleSubmit}
        handleDelete={handleCampaignDelete}
        handleActiveToggle={handleCampaignActiveToggle}
        handleClone={handleClone}
        deleteLoading={deleteLoading}
        pauseLoading={updateLoading}
        cloneLoading={cloneLoading}
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        saveLoading={campaignCreating}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default Campaigns;

