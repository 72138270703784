import { User } from '@auth0/auth0-react';
import { A2P_ORG_ID, Auth0OrganizationData } from '@shared/models';
import { isPreProdSubdomain, PRE_PROD_ORG_NAMES } from '@shared/services';
import { useMembership } from 'apps/a2p-portal/src/contexts/MembershipContext';
import { getSubDomain } from 'apps/a2p-portal/src/providers/auth0-data.provider';
import { orderBy } from 'lodash';
import { IDropdownValue } from '../Dropdown';
import AutocompleteBlack from './AutocompleteBlack';

interface ISubDomainDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  auth0User: User | undefined;
}

const SubDomainDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  auth0User,
}: ISubDomainDropdownProps): JSX.Element => {
  const { organizations } = useMembership();

  const subDomain = getSubDomain();
  const isLocal = window.location.origin.includes('localhost');
  const isPreProd = isPreProdSubdomain(subDomain);

  const options = organizations
    ?.filter(org => !!org && org?.id !== A2P_ORG_ID)
    ?.filter(org => (isPreProd || isLocal) ? PRE_PROD_ORG_NAMES.includes(org?.name?.toLowerCase()?.trim()) : true)
    ?.map(org => getOption(org))
    ?? [];

  const orderedOptions = isLocal
    ? orderBy(options, option => option.value === 'dev', 'desc')
    : orderBy(options, 'label');

  return (
    <AutocompleteBlack
      label="Organization"
      value={value!}
      options={orderedOptions}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      labelColor="text-white"
    />
  );
};

function getOption(organization: Auth0OrganizationData): IDropdownValue {
  return {
    label: organization.display_name,
    value: organization.name,
    additionalData: {
      orgId: organization?.id,
    }
  };
}

export default SubDomainDropdown;
