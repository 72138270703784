import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import { Auth0FrontendUser } from '@shared/models';

export interface Auth0UserData extends Auth0ContextInterface<User> {
  user: Auth0FrontendUser;
}

export function useAuth0UserData(): Auth0UserData {
  const data = useAuth0();

  return {
    ...data,
    user: data?.user as Auth0FrontendUser
  };
}