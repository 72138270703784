import { User } from '@auth0/auth0-react';
import { Disclosure } from '@headlessui/react';
import { Auth0OrganizationID, Auth0OrganizationName, Auth0Role } from '@shared/models';
import { useClientContext } from 'apps/a2p-portal/src/contexts/ClientContext';
import { useMembership } from 'apps/a2p-portal/src/contexts/MembershipContext';
import { SidebarContext } from 'apps/a2p-portal/src/contexts/SidebarContext';
import { useAuth0UserData } from 'apps/a2p-portal/src/hooks/useAuth0Custom';
import useRoles from 'apps/a2p-portal/src/hooks/useRoles';
import { Auth0FrontendConfig, goToOrg } from 'apps/a2p-portal/src/providers/auth0-data.provider';
import { clearCachedClient, getCachedClient, removeAllSessions, setCachedClient, setCachedSelectedOrgName } from 'apps/a2p-portal/src/providers/auth0-storage.provider';
import { getUserOrgs, logout } from 'apps/a2p-portal/src/providers/auth0.provider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IDropdownValue } from '../Form/Dropdown';
import ClientDropdown from '../Form/Dropdowns/ClientDropdown';
import SubDomainDropdown from '../Form/Dropdowns/SubDomainDropdown';
import { Campaigns as CampaignsIcon } from '../Icons/Campaigns';
import { Clients as ClientsIcon } from '../Icons/Clients';
import { Contacts as ContactsIcon } from '../Icons/Contacts';
import { JourneysIcon } from '../Icons/JourneysIcon';
import { Logout as LogoutIcon } from '../Icons/Logout';
import { Segments as SegmentsIcon } from '../Icons/Segments';
import { Settings as SettingsIcon } from '../Icons/Settings';
import { Support as SupportIcon } from '../Icons/Support';
import Festive from '../Other/Festive';
import { getFestiveAssetName } from '../Other/types';
import Shimmer, { ShimmerType } from '../Shimmer';
import { MenuButton } from './MenuButton';
import { INavItem, MenuItem } from './MenuItem';

interface ILeftNavContentProps {
  auth0Config: Auth0FrontendConfig;
}

const LeftNavContent = ({
  auth0Config,
}: ILeftNavContentProps): JSX.Element => {
  const location = useLocation();
  const { devMode, sidebarOpen, setDevMode, setSidebarOpen, activeUser } = useContext(SidebarContext);
  const navigate = useNavigate();
  const { logout: auth0Logout, user: auth0User, isLoading } = useAuth0UserData();
  const { membership: memberships, isMembershipLoaded: isLoaded } = useMembership();
  const { selectedClient, setClient } = useClientContext();
  const openDisc = useRef<HTMLButtonElement>();

  const [subDomainDropdownValue, setSubDomainDropdownValue] = useState({
    label: auth0Config?.label,
    value: auth0Config?.organization,
  });

  useEffect(() => {
    setSubDomainDropdownValue({
      label: auth0Config?.label,
      value: auth0Config?.organization,
    });
  }, [auth0Config]);

  const adminControlsAllowed = useRoles([Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]);
  const usersAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const tcrCampaignsAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const journeysAllowed = false; // useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const allCampaignsAllowed = useRoles([Auth0Role.A2P_SUPER_ADMIN, Auth0Role.A2P_ADMIN]);
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const [showVersion, setShowVersion] = useState(false);

  const getNavbarItems = (): INavItem[] => {
    const navigation: INavItem[] = [
      {
        name: 'Campaigns',
        icon: CampaignsIcon,
        children: [
          ...allCampaignsAllowed ? [{
            name: 'All',
            href: '/all-campaigns',
            visible: true
          }] : [],
          {
            name: 'Campaigns',
            href: '/campaigns',
            visible: true
          },
          {
            name: 'Recurring',
            href: '/recurring-campaigns',
            visible: true
          },
        ]
      },
      {
        name: 'Segments',
        icon: SegmentsIcon,
        children: [
          {
            name: 'Audiences',
            href: '/audiences',
            visible: true
          },
          {
            name: 'Suppressions',
            href: '/suppressions',
            visible: true
          },
          {
            name: 'Custom Data Points',
            href: '/custom-data-points',
            visible: true
          },
        ],
      },
      ...journeysAllowed ? [{
        name: 'Journeys',
        href: '/journeys',
        icon: JourneysIcon
      }] : [],
      {
        name: 'Contacts',
        icon: ContactsIcon,
        children: [
          {
            name: 'Contact Numbers',
            href: '/contacts',
            visible: true
          },
          {
            name: 'Test Contacts',
            href: '/test-numbers',
            visible: true
          },
        ]
      },
      {
        name: 'Clients',
        href: '/clients',
        icon: ClientsIcon,
      },
    ];

    if (adminControlsAllowed) {
      const adminControls = [
        {
          name: 'System Numbers',
          href: '/system-numbers',
          visible: true
        },
      ];
      if (usersAllowed) {
        adminControls.push({
          name: 'Users',
          href: '/users',
          visible: true
        });
      }
      if (tcrCampaignsAllowed) {
        adminControls.push({
          name: 'TCR Campaigns',
          href: '/tcr-campaigns',
          visible: true
        });
      }
      navigation.push({
        name: 'Settings',
        icon: SettingsIcon,
        children: adminControls,
      });
    }

    return navigation;
  };

  const getFullName = (user?: User) => {
    const firstName = activeUser?.firstName ?? user?.given_name ?? user?.app_metadata?.firstName;
    const lastName = activeUser?.lastName ?? user?.family_name ?? user?.app_metadata?.lastName;
    return [firstName?.trim(), lastName?.trim()].filter((n) => n).join(' ');
  };

  const navItems: INavItem[] = getNavbarItems();

  const userOrgs = getUserOrgs(auth0User);

  return (
    <div
      id="nav-content"
      className="justify-between nav-bar bg-midnight-blue"
    >
      {devMode && <Festive />}
      <div className="nav-menu">
        <div className="m-auto select-none logo" style={{ paddingTop: '2rem' }}>
          <img
            id="a2p-logo"
            className="w-auto h-10"
            src={`/${getFestiveAssetName()}`}
            alt="Iris Logo"
            onAuxClick={event => event?.button === 1 && setDevMode(!devMode)}
            onDoubleClick={() => {
              setShowVersion(!showVersion);
            }}
          />
        </div>
        {showVersion && <div className='inline-flex items-center text-white'>{`v${memberships?.version ?? 'N/A'}`}</div>}
        <nav
          className={`flex flex-col flex-1 px-2 space-y-2 bg-midnight-blue`}
          aria-label="Sidebar"
        >
          {!isLoaded ? (
            <Shimmer type={ShimmerType.LIST} rows={5} />
          ) : (
            <>
              <div className='space-y-4'>
                {userOrgs?.length >= 2 && (
                  <SubDomainDropdown
                    auth0User={auth0User}
                    value={subDomainDropdownValue}
                    onChange={(value) => {
                      if (!!value) {
                        if (subDomainDropdownValue.value !== value.value) {
                          const orgId: Auth0OrganizationID = value?.additionalData?.orgId;
                          const orgName: Auth0OrganizationName = value?.value;

                          setCachedSelectedOrgName(orgName);
                          setSubDomainDropdownValue(value!);
                          goToOrg({ orgId, orgName });
                        }
                      }
                    }}
                  />
                )}
                <ClientDropdown
                  value={selectedClient as IDropdownValue}
                  onChange={(newValue) => {
                    const prevClient = selectedClient;
                    // Url navigation or page refresh
                    if (!prevClient) {
                      const jsonClient = getCachedClient(auth0Config, auth0User);
                      if (jsonClient) { // 1. Pulled from storage (on refresh/manual nav)
                        try {
                          setClient(JSON.parse(jsonClient));
                        } catch (e) { // 2. User cleared dropdown somehow
                          console.error(e);
                          setClient(newValue as IDropdownValue);
                        }
                      } else { // 3. Initial page load
                        setClient(newValue as IDropdownValue);
                      }
                    } else { // 4. Standard flow
                      if (newValue) { // Handle undefined (user cleared dropdown) 
                        setClient(newValue as IDropdownValue);
                        setCachedClient(auth0Config, auth0User, JSON.stringify(newValue));
                        // Kick the user back to home if they manually changed the client
                        navigate('/campaigns');
                      } else {
                        return;
                      }
                    };
                  }}
                  placeholder="Select a Client"
                />
              </div>
              {navItems.map((item) =>
                !item.children ? (
                  <MenuItem key={item.name} item={item} active={location.pathname.includes(item.href ?? '')} />
                ) : (
                  <Disclosure
                    as="div"
                    key={item.name}
                    className="space-y-1"
                    defaultOpen={!!item.children?.find((ch) => (location.pathname).includes(ch.href ?? '')) || (location.pathname === '/' && item.name === "Campaigns")}
                  >
                    {({ open, close }) => (
                      <>
                        <MenuButton
                          discRef={openDisc}
                          key={item.name}
                          item={item}
                          active={item.href === location.pathname}
                          open={open}
                          close={close}
                        />
                        <Disclosure.Panel className="space-y-1">
                          {item.children?.map(
                            (subItem) =>
                              subItem?.visible && (
                                <MenuItem
                                  key={subItem.name}
                                  item={subItem}
                                  active={location.pathname.includes(subItem.href ?? '')}
                                />
                              )
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </>
          )}
        </nav>
      </div>
      <div className="flex flex-col flex-shrink-0 px-2 pb-4 mt-4">
        {getFullName(auth0User).length > 0 && (
          <div className="flex flex-col items-center">
            <div className="button-text text-glacier">{`${getFullName(auth0User).toLocaleUpperCase()}`}</div>
            <div className="text-white body-text-small">{auth0User?.email ?? ''}</div>
          </div>
        )}
        <div className="flex justify-center mt-5 space-x-8 align-middle">
          <div
            className="cursor-pointer text-wc-blue"
            onClick={() => window.open('https://iristexts.freshdesk.com/support/home', '_blank')}
          >
            <SupportIcon />
          </div>
          <div
            className="cursor-pointer text-wc-blue"
            onClick={() => {
              removeAllSessions();
              clearCachedClient(auth0Config, auth0User);
              logout({ auth0Config, auth0Logout });
            }}
          >
            <LogoutIcon />
          </div>
        </div>
        <div className="mt-8 text-tiny text-medium-gray">{`© ${new Date().getFullYear()}, Wondercave. All rights reserved.`}</div>
      </div>
    </div>
  );
};

export default LeftNavContent;


