import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { A2P_ORG_ID, Auth0OrganizationID, Auth0Role } from '@shared/models';
import { configure } from 'axios-hooks';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import Users from './components/AdminSettings/Users';
import { Audiences } from './components/Audiences/Audiences';
import AllCampaigns from './components/Campaigns/AllCampaigns';
import CampaignsDetails from './components/Campaigns/CampaignDetails';
import Campaigns from './components/Campaigns/Campaigns';
import CreateCampaign from './components/Campaigns/CreateCampaign';
import { Clients } from './components/Clients/Clients';
import { Contacts } from './components/Contacts/Contacts';
import { CustomDataPoints } from './components/CustomDataPoints/CustomDataPoints';
import HealthCheckNumbers from './components/HealthCheckNumbers/HealthCheckNumbers';
import JourneyDetails from './components/Journeys/JourneyDetails';
import Journeys from './components/Journeys/Journeys';
import Login from './components/Login';
import RecurringCampaigns from './components/RecurringCampaigns/RecurringCampaigns';
import RecurringCampaignsCreate from './components/RecurringCampaigns/RecurringCampaignsCreate';
import RecurringCampaignsDetails from './components/RecurringCampaigns/RecurringCampaignsDetails';
import { Suppressions } from './components/Suppressions/Suppressions';
import SystemNumbers from './components/SystemNumbers/SystemNumbers';
import TCRCampaigns from './components/TCRCampaigns/TCRCampaigns';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { ProtectedRoute } from './components/shared/Auth/ProtectedRoute';
import { useMembership } from './contexts/MembershipContext';
import { initializeWebSocket } from './contexts/SocketContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import { Auth0FrontendConfig } from './providers/auth0-data.provider';
import { Auth0LocalStorage, clearCachedClient, getCachedSelectedOrgName, removeA2pSession, setCachedSelectedOrgName } from './providers/auth0-storage.provider';
import { AUTH0_AUDIENCE, createAuth0Scope, logout } from './providers/auth0.provider';

export interface IAppProps {
  auth0Config: Auth0FrontendConfig;
}

const App = ({
  auth0Config,
}: IAppProps): JSX.Element => {
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    logout: auth0Logout,
    user: auth0User,
    loginWithRedirect,
  } = useAuth0UserData();

  const { setMembership, setMembershipLoaded, auth0LocalStorage } = useMembership();

  const [isConfigured, setIsConfigured] = useState(false);

  function getOrgSession(auth0LocalStorage: Auth0LocalStorage | undefined, orgId: Auth0OrganizationID) {
    return auth0LocalStorage
      ?.sessions
      ?.find(session => session?.user?.org_id === orgId);
  }

  useEffect(() => {
    if (isLoggedIn && auth0User && auth0LocalStorage && auth0Config) {
      const a2pSession = getOrgSession(auth0LocalStorage, A2P_ORG_ID);

      const isMissingUserId = !a2pSession?.user?.user_id || !auth0User?.user_id;
      const isDifferentUser = a2pSession?.user?.user_id !== auth0User?.user_id;

      if (isMissingUserId || isDifferentUser) {
        removeA2pSession();
        clearCachedClient(auth0Config, auth0User);
        logout({ auth0Config, auth0Logout });
      }
    }
  }, [isLoggedIn, auth0User, auth0LocalStorage, auth0Config]);

  useEffect(() => {
    if (isLoggedIn && !!auth0Config && !!auth0User) {
      setMembership({
        organization: auth0Config?.organization,
        roles: [auth0User?.auth_roles?.[0]],
      });

      const cachedOrgName = getCachedSelectedOrgName();

      if (isEmpty(cachedOrgName) || cachedOrgName !== auth0Config?.organization) {
        setCachedSelectedOrgName(auth0Config?.organization);
      }

      setMembershipLoaded(true);
    }
  }, [isLoggedIn, auth0Config, auth0User]);

  async function getAccessToken() {
    if (!auth0Config) {
      return;
    }

    let response: GetTokenSilentlyVerboseResponse | undefined = undefined;

    try {
      response = await getAccessTokenSilently({
        cacheMode: 'on',
        detailedResponse: true,
        authorizationParams: {
          organization: auth0Config.organizationId,
          audience: AUTH0_AUDIENCE,
          scope: createAuth0Scope({ orgName: auth0Config.organization }),
        }
      });
    } catch (error) {
      await loginWithRedirect({
        authorizationParams: {
          organization: auth0Config.organizationId,
          audience: AUTH0_AUDIENCE,
          scope: createAuth0Scope({ orgName: auth0Config.organization }),
        }
      });
    }

    if (response) {
      const token: TokenResponse = {
        id_token: response.id_token,
        access_token: response.access_token,
        expires_in: response.expires_in,
        scope: response.scope,
      };

      await configureAxios(token);
    }
  }

  async function configureAxios(token: TokenResponse) {
    const authAxios = configureAxiosWithAuth0({
      auth0Config,
      token,
      auth0User,
      auth0Logout,
    });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }

  useEffect(() => {
    if (isConfigured) {
      return;
    }

    if (isLoggedIn) {
      const auth = async () => {
        try {
          await getAccessToken();
        } catch (e) {
          console.error(e);
        }
      };

      auth();
    }

    initializeWebSocket(auth0Config);
  }, [isLoggedIn, auth0Config, isConfigured]);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Login
            auth0Config={auth0Config}
          />
        }
      />
      <Route element={<AppWrapper configured={isConfigured} auth0Config={auth0Config} />}>
        <Route element={<Campaigns />} path="/campaigns" />
        <Route element={<CreateCampaign />} path="/campaigns/create" />
        <Route element={<CampaignsDetails />} path="/campaigns/:id" />
        <Route element={<RecurringCampaigns />} path="/recurring-campaigns" />
        <Route element={<CampaignsDetails />} path="/recurring-campaigns/:recurringId/campaigns/:id" />
        <Route element={<RecurringCampaignsCreate />} path="recurring-campaigns/:id/edit" />
        <Route element={<RecurringCampaignsDetails />} path="/recurring-campaigns/:id" />
        <Route element={<RecurringCampaignsCreate />} path="/recurring-campaigns/create" />
        <Route element={<ProtectedRoute roles={[Auth0Role.A2P_SUPER_ADMIN]} />}>
          <Route element={<Journeys />} path="/journeys" />
          <Route element={<JourneyDetails />} path="/journeys/:id" />
        </Route>
        <Route element={<Contacts />} path="/contacts" />
        <Route element={<Audiences />} path="/audiences" />
        <Route element={<Suppressions />} path="/suppressions" />
        <Route element={<CustomDataPoints />} path="/custom-data-points" />
        <Route element={<Clients />} path="/clients" />
        <Route element={<HealthCheckNumbers />} path="/test-numbers" />
        <Route element={<ProtectedRoute roles={[Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]} />}>
          <Route element={<AllCampaigns />} path="/all-campaigns" />
          <Route element={<SystemNumbers />} path="/system-numbers" />
          <Route element={<ProtectedRoute roles={[Auth0Role.A2P_ADMIN]} />}>
            <Route element={<TCRCampaigns />} path="/tcr-campaigns" />
            <Route element={<Users />} path="/users" />
          </Route>
        </Route>
        <Route element={<Navigate to="/campaigns" />} path="/" />
      </Route>
      <Route element={PageNotFound} />
    </Routes >
  );
};

export default App;
