import { Button, ButtonVariantEnum, CalculateIcon, ColorEnum, Radio, RadioCheckedIcon, TextInput } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import { MessageTypeEnum, ProviderNumberTypeEnum, S3BucketEnum } from '@shared/enums';
import { GetSegmentsContactCountRequest, ICampaignVolumeEntity, IHttpResponse, ISignedUrlRequest, SocketName } from '@shared/models';
import { isNil, uuid } from '@shared/services';
import { axiosGet, axiosPost, getQueryParamString } from 'apps/a2p-portal/src/authAxios';
import { useAuth0Context } from 'apps/a2p-portal/src/contexts/Auth0ConfigContext';
import { socketManager } from 'apps/a2p-portal/src/contexts/SocketContext';
import { useCustomDataPoints } from 'apps/a2p-portal/src/hooks/useCustomDataPoints';
import { getCustomDataPointFields } from 'apps/a2p-portal/src/providers/custom-data-point.provider';
import { formatBackendMongoDBQuery } from 'apps/a2p-portal/src/providers/mongodb.provider';
import { isFileLocalSize, isFileMediaType, isFileTollFreeSize } from 'apps/a2p-portal/src/providers/validation.provider';
import axios from 'axios';
import { FastField, FormikErrors, FormikTouched } from 'formik';
import { useEffect, useState } from 'react';
import { Field, RuleType } from 'react-querybuilder';
import NewAudienceDropdown from '../../shared/Form/Dropdowns/NewAudienceDropdown';
import NewSuppressionListDropdown from '../../shared/Form/Dropdowns/NewSuppressionListDropdown';
import SendingSystemNumbersDropdown from '../../shared/Form/Dropdowns/SendingSystemNumbersDropdown';
import { NewFileUpload } from '../../shared/Form/NewFileUpload';
import NewQueryBuilder from '../../shared/Form/NewQueryBuilder/NewQueryBuilder';
import { IRecurringCampaignForm } from '../types';
import './recurring-campaigns-plan.scss';

interface IPlanProps {
  values?: IRecurringCampaignForm;
  errors?: FormikErrors<IRecurringCampaignForm>;
  touched?: FormikTouched<IRecurringCampaignForm>;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, touched: boolean) => void;
  handleChange?: () => void;
  handleBlur?: () => void;
  setLoading: (value: boolean) => void;
  submissionError?: string;
  formErrors?: FormikErrors<IRecurringCampaignForm>;
}

const Plan = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  setLoading,
  submissionError,
  formErrors
}: IPlanProps) => {
  const [inlineSuppressionFields, setInlineSuppressionFields] = useState<Field[]>([]);
  const [contactCountLoading, setContactCountLoading] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [contactCount, setContactCount] = useState(NaN as number);
  const [campaignVolumeId, setCampaignVolumeId] = useState('');
  const [{ data: customDataPointsResponse }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 1000, isActive: true, personalized: true });
  const customDataPoints = customDataPointsResponse?.records ?? [];
  const { auth0Config } = useAuth0Context();

  errors = { ...errors, ...formErrors };

  const calculateContactCount = async (values: IRecurringCampaignForm) => {
    try {
      setCalculateLoading(true);

      const response = await axiosPost<IHttpResponse<ICampaignVolumeEntity>>('/campaign-volumes');
      const campaignVolume = response?.data;

      const request: GetSegmentsContactCountRequest = {
        clientId: values?.clientId!, // will be set once clientId is pulled from context should always be populated.
        audienceIds: values?.audiences?.map(audience => audience?.value) ?? [],
        suppressionIds: values?.suppressions?.map(suppression => suppression?.value) ?? [],
        campaignVolumeId: campaignVolume.id,
        inlineSuppressionFrontendFilter: hasInlineSuppression(values) ? values?.frontendInlineSuppression : null,
        inlineSuppressionBackendFilter: hasInlineSuppression(values)
          ? formatBackendMongoDBQuery(values?.frontendInlineSuppression, customDataPoints)
          : null
      };

      socketManager?.sendMessage<GetSegmentsContactCountRequest>(SocketName.SEGMENT_CONTACT_COUNT, request);

      setContactCount(NaN);
      setCampaignVolumeId(campaignVolume.id);
    } catch (error) {
      console.log(error);
      setContactCount(NaN);
    }
  };
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        if (campaignVolumeId?.length > 0) {
          const response = await axiosGet<IHttpResponse<ICampaignVolumeEntity>>(`/campaign-volumes/${campaignVolumeId}`);
          const campaignVolume = response?.data;

          if (campaignVolume && !campaignVolume.isCalculating) {
            setCalculateLoading(false);
            setContactCount(campaignVolume?.count ?? NaN);
            setContactCountLoading(false);
            setCampaignVolumeId('');
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [campaignVolumeId]);


  useEffect(() => {
    if (values?.showSuppressions === true) {
      const getFields = async () => {
        const fields = await getCustomDataPointFields({
          rules: values?.frontendInlineSuppression?.rules as RuleType[],
          isActive: true,
        });

        setInlineSuppressionFields(fields);
      };

      getFields();
    }
  }, [values?.showSuppressions]);

  const hasInlineSuppression = (values: IRecurringCampaignForm): any => {
    return (values?.frontendInlineSuppression?.rules?.length ?? 0) > 0;
  };

  const uploadMediaFile = async (
    file: File,
    numberType: ProviderNumberTypeEnum,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const validateFileSize = numberType === ProviderNumberTypeEnum.LOCAL ? isFileLocalSize : isFileTollFreeSize;

    if (!file || !isFileMediaType(file) || !validateFileSize(file)) {
      setFieldValue('mediaUrl', '');
      setFieldValue('mediaName', '');
      return;
    }

    const mediaFileKey: string = uuid();

    const s3SignedUrlRequest: ISignedUrlRequest = {
      bucket: S3BucketEnum.MMS,
      key: mediaFileKey,
      action: 'putObject',
      contentType: file.type,
    };

    const s3MediaUrlRequest: ISignedUrlRequest = {
      bucket: S3BucketEnum.MMS,
      key: mediaFileKey,
      action: 'getObject',
      contentType: file.type,
      expiresIn: 31536000, // 1 Year
    };

    try {
      setLoading(true);
      const {
        data: uploadUrl,
      } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3SignedUrlRequest)}`);

      await axios.put(uploadUrl, file, {
        headers: {
          'Content-Type': file.type,
          'Content-Disposition': 'attachment',
        },
      });

      const baseUrl = window.location.hostname === 'localhost' ? 'https://dev.iristexts.com' : auth0Config?.apiUrl.replace('api.', '');
      setFieldValue('mediaUrl', `${baseUrl}/mms-uploads/${mediaFileKey}`);
      setFieldValue('mediaName', file.name);
    } catch (error) {
      console.error('Error when attempting to upload media file');
    } finally {
      setLoading(false);
    }
  };


  return (
    <><div className="flex flex-wrap space-y-8">
      <div className="campaign-plan">
        <h2 className="mb-8 text-black basis-full ">Plan your message</h2>
        <FastField
          component={TextInput}
          id="name"
          name="name"
          label="RECURRING CAMPAIGN TITLE"
          placeholder="Name your campaign"
          value={values?.name}
          error={touched?.name ? errors.name : ''}
          onChange={handleChange}
          onBlur={handleBlur} />

        <Radio
          label="Message Type"
          options={[
            {
              label: 'SMS',
              value: MessageTypeEnum.SMS,
            },
            {
              label: 'MMS',
              value: MessageTypeEnum.MMS,
            },
          ]}
          value={values?.messageType}
          onChange={(newValue) => {
            setFieldValue!('messageType', newValue);
            setFieldTouched!('messageType', true);
          }}
          grow
          error={touched?.messageType ? errors.messageType : undefined}
          checkedIcon={<RadioCheckedIcon color={ColorEnum.GLACIER} />}
        />
        <SendingSystemNumbersDropdown
          setDefault={true}
          newVersion={true}
          value={values?.systemNumberId}
          onChange={(newValue) => {
            if (newValue as IDropdownValue) {
              setFieldValue!('systemNumberId', newValue);
            }
          }}
          disabled={false}
          showError={!!touched?.systemNumberId}
          errorMessage={errors.systemNumberId?.value?.toString() ?? ''}
          onBlur={() => {

          }} />
        {values?.messageType === MessageTypeEnum.MMS && (
          <>
            <div>
              <NewFileUpload
                id="mediaFile"
                name="mediaFile"
                label="Media File"
                accepts="image/*,video/*,.pdf"
                value={values.mediaFile}
                error={errors.mediaFile as any}
                onBlur={handleBlur}
                placeholder="Drop your file here"
                onChange={(fileUploadEvent: any) => {
                  const file = fileUploadEvent.target.files[0];
                  setFieldTouched!('mediaFile', true);
                  setFieldValue!('mediaFile', file);
                  if (file.type.match('video.*')) {
                    setFieldValue!('isMMSVideo', true);
                  } else {
                    setFieldValue!('isMMSVideo', false);
                  }
                  uploadMediaFile(file, ProviderNumberTypeEnum.LOCAL, setFieldValue!);

                }} />
            </div>
            <div className='flex'></div>
          </>
        )}
        <div className='flex'></div>
        <div className="border-b-2 border-gray shrink-0"></div>
        <h2 className="mb-8 text-black basis-full">Select Segments</h2>

        <NewAudienceDropdown
          value={values?.audiences}
          onChange={(newValue) => {
            setFieldValue!('audiences', newValue);
          }}
          onBlur={() => {
            setFieldTouched!('audiences', true);
          }}
          showError={!!touched?.audiences}
          errorMessage={errors.audiences?.toString()}
          multiple={true} />


        <NewSuppressionListDropdown
          value={values?.suppressions}
          onChange={(newValue) => {
            setFieldValue!('suppressions', newValue);
          }}
          onBlur={() => {
            setFieldTouched!('suppressions', true);
          }}
          errorMessage={errors.suppressions as string}
          showError={!!(touched?.suppressions && errors.suppressions)}
          multiple={true} />


        <FastField
          component={TextInput}
          id="externalId"
          name="externalId"
          label={<div className="flex items-center mb-5">
            <h5>{'EXTERNAL ID (OPTIONAL)'}</h5>
            {/* <Info className="ml-1" text="Include an ID from an external source." /> */}
          </div>}
          value={values?.externalId}
          errors={errors.externalId}
          touched={touched?.externalId}
          onChange={handleChange} />
        <Radio
          label="Do you need to ADD Inline Suppressions?"
          options={[
            {
              label: 'Yes',
              value: true,
            },
            {
              label: 'No',
              value: false,
            },
          ]}
          value={values?.showSuppressions}
          grow
          onChange={(newValue) => {
            setFieldValue!('showSuppressions', newValue);
          }}
          checkedIcon={<RadioCheckedIcon color={ColorEnum.GLACIER} />}
        />
      </div>
      {values?.showSuppressions && (
        <><h2 className="mb-4 text-black basis-full">Inline Suppression</h2>
          <span className='plan-query-builder'>
            <NewQueryBuilder
              fields={inlineSuppressionFields}
              query={values.frontendInlineSuppression}
              setQuery={(newValue) => {
                setFieldValue!('frontendInlineSuppression', newValue);
              }}
              maxRules={5}
              hideAddQuery
            />
          </span>

        </>
      )}
    </div><div>
        <div className='flex justify-between mt-8'>
          <div className="flex items-center">
            <Button
              variant={ButtonVariantEnum.GLACIER}
              disabled={values?.audiences.length === 0 || calculateLoading}
              onClick={() => calculateContactCount(values!)}
              trailingIcon={<div className={calculateLoading ? 'spin' : ''}>
                <CalculateIcon />
              </div>}
            >
              Calculate Campaign Volume
            </Button>
            {!contactCountLoading && !isNil(contactCount) && (
              <h3 className="ml-8 text-wc-blue">{contactCount} messages</h3>
            )}
          </div>
          {submissionError && <h3 className='flex items-center mt-3 text-red-600'>{submissionError}</h3>}
        </div>
      </div></>

  );
};

export default Plan;