import { GlacierCheckbox } from '@Wonder-Cave/ui';
import { getCustomDataPointFields } from 'apps/a2p-portal/src/providers/custom-data-point.provider';
import { useEffect, useState } from 'react';
import { Field, RuleGroupTypeIC, RuleType } from 'react-querybuilder';
import NewQueryBuilder from '../Form/NewQueryBuilder/NewQueryBuilder';

export interface InlineSuppressionsDetailProps {
  inlineSuppresions: RuleGroupTypeIC;
}

const InlineSuppressionsDetail = ({ inlineSuppresions }: InlineSuppressionsDetailProps) => {
  const [inlineSuppressionFields, setInlineSuppressionFields] = useState<Field[]>([]);
  useEffect(() => {
    const getFields = async () => {
      const fields = await getCustomDataPointFields({
        rules: inlineSuppresions.rules as RuleType[],
        isActive: true,
      });
      setInlineSuppressionFields(fields);
    };
    getFields();
  }, [inlineSuppresions]);


  return (
    <>
      <div className="flex flex-wrap">
        <div className="basis-1/2">
          <GlacierCheckbox
            id="inline-suppression"
            label="Inline Suppression"
            readOnly
            checked={inlineSuppresions.rules.length > 0 ? true : false}
          />
        </div>
      </div>
      <div className="flex -ml-4">
        <span className='plan-query-builder'>
          <NewQueryBuilder
            fields={inlineSuppressionFields}
            query={inlineSuppresions}
            setQuery={(newValue) => {
              // setFieldValue!('frontendInlineSuppression', newValue);
            }}
            maxRules={5}
            hideAddQuery
            disabled={true}
          />
        </span>
      </div>
    </>
  );
};

export default InlineSuppressionsDetail;
