import { ICustomDataPointEntity } from '@shared/models';
import { Button, ButtonVariantEnum, Info } from '@Wonder-Cave/ui';

interface ITagsProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  tags: ICustomDataPointEntity[];
}

const Tags = ({ tags, onClick }: ITagsProps) => {
  return (
    <div className="flex flex-wrap mb-2">
      <div className="flex items-center w-full mb-5">
        <h5 className='shrink-0'>CUSTOM DATA POINTS</h5>
        <Info className="ml-2" bubbleWrapperClassName='w-[12rem]' text="Click on the tags below to add it to your message." />
      </div>
      <div className="mb-auto">
        <Button
          key={'link'}
          variant={ButtonVariantEnum.TAG}
          className="mb-2 mr-2 h-fit bg-grass"
          type="button"
          onClick={onClick}
        >
          Link
        </Button>
        <Button
          key={'name'}
          variant={ButtonVariantEnum.TAG}
          className="mb-2 mr-2 h-fit bg-grass"
          type="button"
          onClick={onClick}
        >
          Name
        </Button>
        {tags?.map((tag) => {
          return (
            <Button
              key={tag.id}
              variant={ButtonVariantEnum.TAG}
              className="mb-2 mr-2 h-fit bg-mint"
              type="button"
              onClick={onClick}
            >
              {tag.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default Tags;
