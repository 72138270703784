import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0OrganizationData, Auth0OrganizationID, Auth0OrganizationName, Auth0UserMembership } from '@shared/models';
import { addParam, getRedirectUrl } from '@shared/services';
import { StrictMode, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AppMain from './AppMain';
import { AUTH0_MAIN_CONFIG, Auth0ConfigContextProvider } from './contexts/Auth0ConfigContext';
import { IMembershipContext, MembershipContextProvider } from './contexts/MembershipContext';
import { Auth0FrontendConfig, getSubDomain, getUrlParam } from './providers/auth0-data.provider';
import { Auth0LocalStorage } from './providers/auth0-storage.provider';
import { AUTH0_AUDIENCE, createAuth0Scope } from './providers/auth0.provider';
import './styles.scss';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

function Auth0App(): JSX.Element {
  const BASE_URI = getRedirectUrl(window.location.origin);

  const [auth0Config, setAuth0Config] = useState<Auth0FrontendConfig | undefined>(undefined);
  const [loadedConfig, setLoadedConfig] = useState<Auth0FrontendConfig | undefined>(undefined);

  const getOrganizationId = (): Auth0OrganizationID | undefined => {
    return getUrlParam('organization')
      ?? auth0Config?.organizationId
      ?? undefined;
  };

  const getOrganizationName = (): Auth0OrganizationName | undefined => {
    const subDomain = getSubDomain();

    return getUrlParam('org')
      ?? auth0Config?.organization
      ?? subDomain
      ?? undefined;
  };

  interface GetRedirectUrlRequest {
    orgId: string | undefined;
    orgName: string | undefined;
  }

  const getMainRedirectUrl = ({ orgId, orgName }: GetRedirectUrlRequest) => {
    return addParam(getOrgRedirectUrl({ orgId, orgName }), `main=true`);
  };

  const getOrgRedirectUrl = ({ orgId, orgName }: GetRedirectUrlRequest) => {
    const params: string[] = [];

    if (orgName) {
      params.push(`org=${orgName}`);
    }

    if (orgId) {
      params.push(`organization=${orgId}`);
    }

    return `${BASE_URI}?${params.join('&')}`;
  };

  const isLocal = window.location.origin.includes('localhost');

  // Auth0 Controlled Params
  const invitation = getUrlParam('invitation');
  const orgId = getOrganizationId();

  // Custom Params
  const orgName = getOrganizationName();

  const [membership, setMembership] = useState<Auth0UserMembership>();
  const [organizations, setOrganizations] = useState<Auth0OrganizationData[]>();
  const [isMembershipLoaded, setMembershipLoaded] = useState(false);
  const [auth0LocalStorage, setAuth0LocalStorage] = useState<Auth0LocalStorage | undefined>();

  const membershipContext: IMembershipContext = {
    membership,
    setMembership,

    organizations,
    setOrganizations,

    auth0LocalStorage,
    setAuth0LocalStorage,

    isMembershipLoaded,
    setMembershipLoaded,
  };

  const setConfig = (config: Auth0FrontendConfig) => {
    setAuth0Config(config);
    setLoadedConfig(config);
  };

  useEffect(() => {
    if (!!auth0Config && !!loadedConfig && auth0Config.organization !== loadedConfig.organization) {
      setLoadedConfig(undefined);
    }
  }, [auth0Config, loadedConfig]);

  return (
    <Auth0ConfigContextProvider value={{ auth0Config, setAuth0Config }}>
      <MembershipContextProvider value={membershipContext}>
        {!loadedConfig && (
          <Auth0Provider
            domain='wondercave.us.auth0.com'
            skipRedirectCallback={!window.location.href.includes(`main=`)}
            clientId={AUTH0_MAIN_CONFIG.clientId}
            cacheLocation={!!invitation ? 'memory' : 'localstorage'}
            cookieDomain={isLocal ? 'localhost' : '.iristexts.com'}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{
              redirect_uri: getMainRedirectUrl({ orgId, orgName }),
              invitation: invitation,
              organization: AUTH0_MAIN_CONFIG.organizationId,
              scope: createAuth0Scope({ orgName: AUTH0_MAIN_CONFIG.organization }),
              audience: AUTH0_AUDIENCE,
            }}
          >
            <AppMain
              targetOrgId={orgId}
              targetOrgName={orgName}
              setAuth0Config={setConfig}
            />
          </Auth0Provider>
        )}

        {!!loadedConfig && (
          <Auth0Provider
            key={`${loadedConfig.clientId}_${loadedConfig.organizationId}`}
            domain='wondercave.us.auth0.com'
            skipRedirectCallback={window.location.href.includes('main=')}
            clientId={loadedConfig.clientId}
            cacheLocation={'localstorage'}
            cookieDomain={isLocal ? 'localhost' : '.iristexts.com'}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{
              redirect_uri: getOrgRedirectUrl({ orgId: loadedConfig.organizationId, orgName: loadedConfig.organization }),
              organization: loadedConfig.organizationId,
              scope: createAuth0Scope({ orgName: loadedConfig.organization }),
              audience: AUTH0_AUDIENCE,
            }}
          >
            <App
              auth0Config={loadedConfig}
            />
          </Auth0Provider>
        )}
      </MembershipContextProvider>
    </Auth0ConfigContextProvider>
  );
}