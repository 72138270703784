
import { Autocomplete, Error } from '@Wonder-Cave/ui';
import useSystemNumbersByClientId from 'apps/a2p-portal/src/hooks/useSystemNumbersByClient';
import { useEffect } from 'react';
import { useClientContext } from '../../../../contexts/ClientContext';
import { formatPhoneNumber } from '../../numbers/number';
import { IDropdownValue } from '../Dropdown';
import OldAutocomplete from './Autocomplete';

interface ISendingSystemNumbersDropdownProps {
  onChange: (newValue: IDropdownValue | undefined) => void;
  value: IDropdownValue | undefined;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  newVersion?: boolean;
  clientId?: string;
  label?: string;
  setDefault?: boolean;
}

const SendingSystemNumbersDropDown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  newVersion,
  clientId,
  label,
  setDefault
}: ISendingSystemNumbersDropdownProps): JSX.Element => {

  let clientIdToSearch = clientId;
  // if no clientId is passed in as a prop leverage the current active client on users browser
  if (!clientIdToSearch) {
    const { selectedClientId } = useClientContext();
    clientIdToSearch = selectedClientId;
  }

  // if leveraging on clients form leverage passed in clientId, if not passed in pull clientId from context
  const [{ data: systemNumberData, loading: systemNumberLoading, error: systemNumberError }] = useSystemNumbersByClientId(clientIdToSearch);

  const systemNumber: IDropdownValue[] =
    systemNumberData?.records.map((systemNumber) => ({
      label: (systemNumber.phoneNumber.length === 10) ? formatPhoneNumber(systemNumber.phoneNumber) : systemNumber.phoneNumber,
      value: systemNumber.id ?? '',
      additionalData: { providerId: systemNumber.providerId }
    })) ?? [];

  const currentValue = systemNumber.find((number) => number.value === value?.value);

  useEffect(() => {
    if (systemNumber.length === 1 && setDefault) {
      onChange(systemNumber[0]);
    }
  }, [systemNumberData]);


  return (
    newVersion ?
      (<div>
        <Autocomplete
          gray={disabled || systemNumber.length === 1 || systemNumber.length === 0 && !systemNumberLoading}
          label={label ?? "Sending Number"}
          value={value as any}
          loading={systemNumberLoading}
          options={systemNumber}
          onChange={onChange}
          onBlur={onBlur}
          showError={showError}
          error={errorMessage}
          disabled={disabled || systemNumber.length === 1 || systemNumber.length === 0 && !systemNumberLoading}
        />
        {((systemNumber?.length ?? 0) <= 0 && !systemNumberLoading) && <Error color={"text-flame-orange"} text={"You don't have any active sending numbers assigned to this client."} />}
      </div>)
      :
      (<div>
        <OldAutocomplete
          label={label ?? "Sending Number"}
          value={currentValue}
          loading={systemNumberLoading}
          options={systemNumber}
          onChange={onChange}
          onBlur={onBlur}
          showError={showError}
          error={errorMessage}
          disabled={disabled || (systemNumber.length === 1 && setDefault) || (systemNumber.length === 0 && !systemNumberLoading)}
        />
        {((systemNumber?.length ?? 0) <= 0 && !systemNumberLoading) && <Error color={"text-flame-orange"} text={"You don't have any active sending numbers assigned to this client."} />}
      </div>)
  );
};

export default SendingSystemNumbersDropDown;