import { LogoutOptions } from '@auth0/auth0-react';
import { A2P_ORG_NAME, Auth0AppMetadata, Auth0CreateUserRequest, Auth0FrontendUser, Auth0GetUserDetailsRequest, Auth0OrganizationName, Auth0Role, Auth0UpdateUserRequest, Auth0User, Auth0UserDetails, IHttpResponse } from '@shared/models';
import { getRedirectUrlByOrg } from '@shared/services';
import { axiosPost } from '../authAxios';
import { IUserForm } from '../components/AdminSettings/types';
import { Auth0FrontendConfig } from './auth0-data.provider';

export type Auth0Logout = (options?: LogoutOptions | undefined) => void;

export const AUTH0_SCOPE = "openid profile email";
export const AUTH0_AUDIENCE = "a2p";

export interface Auth0LogoutRequest {
  auth0Config: Auth0FrontendConfig;
  auth0Logout: Auth0Logout;
}

export function logout({ auth0Config, auth0Logout }: Auth0LogoutRequest) {
  auth0Logout({
    logoutParams: {
      returnTo: getRedirectUrlByOrg({
        baseUrl: window?.location?.origin,
        orgId: auth0Config.organizationId,
        orgName: auth0Config.organization,
      })
    }
  });
}

export interface CreateAuth0ScopeRequest {
  orgName: Auth0OrganizationName;
}

export function createAuth0Scope({ orgName }: CreateAuth0ScopeRequest) {
  return `${AUTH0_SCOPE} ${orgName}`;
}

export function getUserOrgs(auth0User: Auth0FrontendUser | undefined) {
  return auth0User?.organizations?.filter(org => org?.name !== A2P_ORG_NAME) ?? [];
}

export async function getUserDetails(request: Auth0GetUserDetailsRequest) {
  const response = await axiosPost<IHttpResponse<Auth0UserDetails>>('/auth0-get-user-details', request);
  return response.data;
}

export async function createUser(form: IUserForm) {
  const request: Auth0CreateUserRequest = {
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        organization: form.organization.value,
        roles: [form.role.value],
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-create-user', request);
}

export async function updateUser(details: Auth0UserDetails, form: IUserForm) {
  const request: Auth0UpdateUserRequest = {
    user_id: details.user_id,
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        organization: form.organization.value,
        roles: [form.role.value],
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-update-user', request);
}

export function isAdmin(user?: Auth0FrontendUser) {
  const organization = user?.organization_name;
  const app_metadata = user?.app_metadata as Auth0AppMetadata;
  const membership = app_metadata?.memberships.find((membership) => membership?.organization === organization);
  return membership?.roles?.some((role) => [Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]?.includes(role)) ?? false;
}

export function getAppRoles(roles: Auth0Role[] = []) {
  return roles?.filter((role: Auth0Role) => Object.values(Auth0Role).includes(role)) ?? [];
}

export function getUserRole(orgName: Auth0OrganizationName | undefined, user: Auth0FrontendUser | Auth0User | undefined) {
  const app_metadata: Auth0AppMetadata = <any>user?.app_metadata;
  const membership = app_metadata?.memberships.find(m => m?.organization === orgName);
  return getAppRoles(membership?.roles)?.[0];
}
