import { MessageTypeEnum, RecurringCampaignCooldownPeriodEnum, RecurringCampaignCooldownTypeEnum, RecurringCampaignFrequencyEnum, RecurringCampaignStatusEnum } from '@shared/enums';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import { RuleGroupTypeIC } from 'react-querybuilder';
import * as yup from 'yup';
import { isFileLocalSize, isFileMediaType } from '../../providers/validation.provider';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { IMenuItem } from '../shared/Table/Actions';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';
import { CampaignTypeEnum } from '../shared/recurringcampaigns/campaign-type-enum';

export interface IFormErrors {
  [field: string]: string;
}

export interface IFormData {
  [field: string]: string;
}

export interface IRecurringCampaignsBuildForm {
  url?: string;
  domainId?: string | null;
  clickTrackingEnabled?: boolean;
  hasNoEndDate?: boolean;
  message: string;
  startsAt: Date;
  endsAt?: Date;
  sendingStartsAt?: Date;
  sendingEndsAt?: Date;
  frequency?: RecurringCampaignFrequencyEnum;
  cooldownPeriod?: RecurringCampaignCooldownPeriodEnum;
  cooldownPeriodCount: number;
  cooldownType?: RecurringCampaignCooldownTypeEnum;
}

export interface IRecurringCampaignsPreviewForm {
  url?: string;
  domainId?: string | null;
  domain?: string | null;
  clickTrackingEnabled?: boolean;
  hasNoEndDate?: boolean;
  message: string;
  startsAt: Date;
  endsAt?: Date;
  sendingStartsAt?: Date;
  frequency?: RecurringCampaignFrequencyEnum;
}

export interface IRecurringCampaignForm extends IRecurringCampaignsBuildForm {
  id?: string; // Only populates after save
  clientId?: string;
  // Misc.
  status: RecurringCampaignStatusEnum;
  sendTestMessages?: boolean;
  campaignType: CampaignTypeEnum;
  frequencyCount: number;

  // Plan
  isMMSVideo?: boolean;
  mediaUrl?: string;
  showSuppressions: boolean,
  name: string;
  client?: IDropdownValue;
  messageType?: MessageTypeEnum;
  externalId?: string;
  mediaFile?: any;
  mediaName?: string;
  audiences: IDropdownValue[];
  suppressions: IDropdownValue[];
  frontendInlineSuppression: RuleGroupTypeIC;
  systemNumberId: IDropdownValue;
}

export interface IRecurringCampaignsPlanForm {
  name: string;
  messageType?: MessageTypeEnum;
  mediaFile?: any;
  suppressions: IDropdownValue[];
  audiences: IDropdownValue[];
  externalId?: string | null;
}

export const recurringCampaignPlanFormSchema: yup.SchemaOf<IRecurringCampaignsPlanForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  messageType: yup.mixed<MessageTypeEnum>().required(),
  isMMSVideo: yup.boolean().nullable(),
  mediaFile: yup.mixed().when('messageType', {
    is: (messageType: MessageTypeEnum) => {
      return messageType === MessageTypeEnum.MMS;
    },
    then: yup.mixed()
      .required('Required')
      .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean =>
        isFileMediaType(file)
      )
      .test('fileSize', 'File is too large. File must be smaller than 850kb', (file: File): boolean =>
        isFileLocalSize(file)
      )
  }),
  audiences: yup
    .array().of(
      yup.object().shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ).min(1, 'Audience must have at least one rule.'),
  externalId: yup.string().nullable(),
  suppressions: yup
    .array().of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
  campaignType: yup.mixed<CampaignTypeEnum>().defined('Required').required('Required'),
  systemNumberId:
    yup.object().shape({
      label: yup.string().defined(),
      value: yup.mixed().required('Required'),
      additionalData: yup.mixed(),
    })
});

// export const recurringcampaignsFormSchema: yup.SchemaOf<IRecurringCampaignForm> = yup.object().shape({
//   message: yup
//     .string()
//     .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
//     .when('messageType', {
//       is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
//       then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
//       otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
//     })
//     .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
//       return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
//     })
//     .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
//       return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
//     })
//     .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
//       return true;
//       //return checkSHAFTCompliance(message);
//     })
//     .test('segmentCount', 'Segment count must not be larger than 10', (message: string | undefined, context): boolean => {
//       if (context.parent.messageType === MessageTypeEnum.SMS) {
//         return true;
//         // return getSMSMessageSegmentCount(message ?? '') <= 10;
//       } else {
//         return true;
//       }
//     })
//     .defined('Required'),
//   clickTrackingEnabled: yup.boolean(),
//   twoWayEnabled: yup.boolean(),
//   generateUniqueLinks: yup.boolean(),
//   url: yup.string().when('clickTrackingEnabled', {
//     is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
//     then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
//   }),
//   domainId: yup.string().when('clickTrackingEnabled', {
//     is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
//     then: yup.string().defined('Required'),
//   }),
//   domain: yup.string().when('clickTrackingEnabled', {
//     is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
//     then: yup.string().defined('Required'),
//   }),
//   startsAt: yup
//     .date()
//     .min(new Date(), 'Campaign cannot start in the past')
//     .typeError('Invalid Date')
//     .defined('Required')
//   ,
//   endsAt: yup
//     .date()
//     .typeError('Invalid Date')
//     .defined('Required')
//     .min(yup.ref('startsAt'), 'End date cannot be before Start date')
//     .test('isMoreThanTwoDays', `Campaign must end within 2 business days of start time.`, (value, context): boolean => {
//       return true;
//       // return !isMoreThanTwoDays(context.parent.startsAt, context.parent.endsAt);
//     })
// });

export const defaultRecurringCampaignsTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Title',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
      noUnderline: true,
      renderColumn: columnRenders['name']
    },
    {
      headerName: 'Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Total Sent',
      fieldName: 'totalMessagesSent',
      fieldType: FilterDataTypeEnum.NUMBER
    },
    {
      headerName: 'Duration',
      fieldName: 'duration',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['duration'],
    },
    {
      headerName: 'Actions',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS
    }
  ];
};

export const defaultRecurringCampaignCampaignsTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 5,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getCampaignColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any, actions: IMenuItem[]): IColumn[] => {
  return [
    {
      headerName: 'Title',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
      noUnderline: true,
      renderColumn: columnRenders['name']
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
      renderColumn: columnRenders['status']
    },
    {
      headerName: 'Scheduled Messages',
      fieldName: 'totalMessagesScheduled',
      fieldType: FilterDataTypeEnum.NUMBER,
    },
    {
      headerName: 'Delivery Progress',
      fieldName: 'deliveredProgress',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['deliveredProgress'],
    },
    {
      headerName: 'Click Rate',
      fieldName: 'clickRate',
      renderColumn: columnRenders['clickRate'],
      fieldType: FilterDataTypeEnum.NUMBER
    },
    {
      headerName: 'Opt-Out Rate',
      fieldName: 'optoutRate',
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['optoutRate'],
    },
    {
      headerName: '',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS,
      action: {
        items: actions
      }
    }
  ];
};