import { Autocomplete } from '@Wonder-Cave/ui';
import { IDropdownValue } from '@Wonder-Cave/ui/dist/Autocomplete';
import useTestNumbers from 'apps/a2p-portal/src/hooks/useTestNumbers';
import { useEffect } from 'react';

interface ITestNumbersDropdownProps {
  value: number[] | undefined;
  onChange: (newValue?: (IDropdownValue | undefined)[]) => void;
  onBlur?: () => void;
  allClients?: boolean;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError: boolean;
  client: IDropdownValue;
  loading?: boolean;
  selectAll: boolean;
}

const TestNumbersDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  client,
  loading,
  selectAll
}: ITestNumbersDropdownProps): JSX.Element => {
  const [{ data: testNumbersData, loading: testNumbersLoading, error: testNumbersError }, searchTestNumbers] = useTestNumbers();
  const testNumbers: IDropdownValue[] =
    testNumbersData?.records
      ?.map((testNumber) => {

        return {
          label: testNumber.name + ' - ' + (testNumber.phoneNumber.toString() ?? ''),
          value: testNumber.id,
          additionalData: {
            //allClients: testNumber.allClients,
            number: testNumber.phoneNumber,
          },
        };
      })
    ?? [];

  useEffect(() => {
    if (selectAll) {
      if (testNumbersData?.records.length === undefined) {
        onChange([]);
        return;
      }
      const testNumbers = testNumbersData?.records
        ?.map((testNumber) => {

          return {
            label: testNumber.name + ' - ' + (testNumber.phoneNumber.toString() ?? ''),
            value: testNumber.id,
            additionalData: {
              //allClients: testNumber.allClients,
              number: testNumber.phoneNumber,
            },
          };
        }).splice(0, 20).filter((tn) => tn?.additionalData?.number);
      //call on change maybe
      onChange(testNumbers);

    } else if (!selectAll) {
      const testNumbers = testNumbersData?.records
        ?.map((testNumber) => {

          return {
            label: testNumber.name + ' - ' + (testNumber.phoneNumber.toString() ?? ''),
            value: testNumber.id,
            additionalData: {
              //allClients: testNumber.allClients,
              number: testNumber.phoneNumber,
            },
          };
        });
      if (testNumbers?.length === value?.length || value?.length === 20) {
        //call on change maybe
        onChange([]);
      }
    }

  }, [selectAll]);


  return (
    <Autocomplete
      label=""
      placeholder="Type to search contacts"
      //loading={testNumbersLoading || loading}
      loading={testNumbersLoading}
      value={selectAll ? testNumbers.splice(0, 20).filter((tn) => tn?.additionalData?.number) : testNumbers.filter((tn) => value?.includes(tn?.additionalData?.number))}
      options={testNumbers}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      multiple
      showError={showError}
    />
  );
};

export default TestNumbersDropdown;
