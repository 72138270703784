import { Autocomplete, Checkbox, DateTimePicker, Info, TextInput } from '@Wonder-Cave/ui';
import { RecurringCampaignCooldownPeriodEnum, RecurringCampaignCooldownTypeEnum, RecurringCampaignFrequencyEnum } from '@shared/enums';
import 'draft-js/dist/Draft.css';
import { FastField } from 'formik';
import { IDropdownValue } from '../../../shared/Form/Dropdown';
import { IBuildProps } from './types';

const BuildScheduleMessage = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  readOnly,
}: IBuildProps) => {
  const frequencyOptions: IDropdownValue[] = [
    { label: 'Days', value: RecurringCampaignFrequencyEnum.DAYS },
    { label: 'Weeks', value: RecurringCampaignFrequencyEnum.WEEKS },
    { label: 'Months', value: RecurringCampaignFrequencyEnum.MONTHS },
  ];

  const cooldownOptions: IDropdownValue[] = [
    { label: 'Days', value: RecurringCampaignCooldownPeriodEnum.DAYS },
    { label: 'Weeks', value: RecurringCampaignCooldownPeriodEnum.WEEKS },
    { label: 'Months', value: RecurringCampaignCooldownPeriodEnum.MONTHS }
  ];
  const cooldownTypeOptions: IDropdownValue[] = [
    { label: 'No Cool Down', value: RecurringCampaignCooldownTypeEnum.NONE },
    { label: 'Custom', value: RecurringCampaignCooldownTypeEnum.CUSTOM }
  ];

  return (
    <div className='flex flex-col'>
      <h4 className='mb-6 text-dark-gray'>
        1. Select Frequency: How often would you like your campaign to run?
      </h4>

      <div className='flex'>
        <div className='flex-grow min-w-[calc(30%-1rem)] max-w-[calc(30%-1rem)] select-none'>
          <h5 className='flex mb-5 uppercase text-medium-gray'>
            <span>SELECT FREQUENCY</span>
          </h5>
          <div className='flex'>
            <div className='mr-4'>
              <FastField
                component={TextInput}
                type='number'
                id="frequencyCount"
                name="frequencyCount"
                value={values?.frequencyCount}
                error={touched?.frequencyCount && errors?.frequencyCount ? errors.frequencyCount : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='w-full'>
              <Autocomplete
                value={frequencyOptions.find((option) => option.value === values?.frequency)}
                readOnly={true}
                options={frequencyOptions}
                onChange={(dropdownValue: IDropdownValue | undefined) => {
                  setFieldTouched!('frequency', true);
                  setFieldValue!('frequency', dropdownValue?.value);
                }}
                showError={!!errors?.frequency}
                error={touched?.frequency ? errors?.frequency : ''}
              />

            </div>
          </div>
        </div>


        <div className='min-w-[1rem]'>
        </div>

        <div className='flex min-w-[calc(20%-1rem)] max-w-[calc(20%-1rem)]'>
          <FastField
            label='SELECT TIME'
            component={DateTimePicker}
            disabled={!!readOnly}
            full
            onlyTime
            name='sendingStartsAt'
            value={new Date(values?.sendingStartsAt!)}
            error={touched?.sendingStartsAt && errors?.sendingStartsAt ? (errors?.sendingStartsAt as string) : ''}
            onChange={(newValue: Date) => {
              setFieldTouched!('sendingStartsAt', true);
              setFieldValue!('sendingStartsAt', newValue);
            }}
          />
        </div>

        <div className='min-w-[1rem]'>
        </div>

        <div className='flex-grow select-none'>
          <h5 className='flex mb-5 uppercase text-medium-gray'>
            <span>COOL DOWN PERIOD</span>
            <Info
              className="items-center ml-2 !normal-case"
              text="Prevent message fatigue with the Cool Down Period. After receiving a message, recipients are automatically added to a cool down group to ensure they won't be contacted again until the period has passed."
              width={300}
              position='BOTTOM'
              disableDivEnter
            />
          </h5>
          <div className='flex'>
            <div className='min-w-[calc(45%-1rem)] max-w-[calc(45%-1rem)] mr-4'>
              <Autocomplete
                readOnly={true}
                value={cooldownTypeOptions.find((option) => option.value === values?.cooldownType)}

                options={cooldownTypeOptions}
                onChange={(dropdownValue: IDropdownValue | undefined) => {
                  setFieldTouched!('cooldownType', true);
                  setFieldValue!('cooldownType', dropdownValue?.value);
                }}
                onBlur={() => {
                  setFieldTouched!('cooldownType', true);
                }}
                showError={!!errors?.cooldownType}
                error={touched?.cooldownType ? errors?.cooldownType : ''}
              />
            </div>
            {values?.cooldownType === RecurringCampaignCooldownTypeEnum.CUSTOM &&
              <>
                <div className='min-w-[calc(25%-1rem)] max-w-[calc(25%-1rem)] mr-4'>
                  <FastField
                    component={TextInput}
                    type='number'
                    id="cooldownPeriodCount"
                    name="cooldownPeriodCount"
                    value={values?.cooldownPeriodCount}
                    error={touched?.cooldownPeriodCount && errors?.cooldownPeriodCount ? errors.cooldownPeriodCount : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='w-max-[20%]'>
                  <Autocomplete
                    readOnly={true}
                    value={cooldownOptions.find((option) => option.value === values?.cooldownPeriod)}

                    options={cooldownOptions}
                    onChange={(dropdownValue: IDropdownValue | undefined) => {
                      setFieldTouched!('cooldownPeriod', true);
                      setFieldValue!('cooldownPeriod', dropdownValue?.value);
                    }}
                    onBlur={() => {
                      setFieldTouched!('cooldownPeriod', true);
                    }}
                    showError={!!errors?.cooldownPeriod}
                    error={touched?.cooldownPeriod ? errors?.cooldownPeriod : ''}
                  />
                </div>
              </>
            }
          </div>
          {values?.cooldownType === RecurringCampaignCooldownTypeEnum.NONE &&
            <div className='flex justify-start pb-4 body-text-medium text-medium-gray'>
              Recipients will receive all messages sent for the duration of this recurring campaign.
            </div>
          }
        </div>
      </div>

      <h4 className='mb-6 text-dark-gray'>
        2. Select Duration: When would you like to start your campaign?
      </h4>

      <div className='flex flex-row justify-between w-full recurring-dates'>
        <div className='flex-grow min-w-[calc(50%-1rem)] max-w-[calc(50%-1rem)]'>
          <FastField
            label='START DATE'
            component={DateTimePicker}
            disabled={!!readOnly}
            onlyDate
            name='startsAt'
            value={new Date(values?.startsAt!).setHours(0, 0, 0, 0)}
            error={touched?.startsAt && errors?.startsAt ? (errors?.startsAt as string) : ''}
            onChange={(newValue: Date) => {
              setFieldTouched!('startsAt', true);
              setFieldValue!('startsAt', newValue);
            }}
          />
        </div>
        <div className='relative flex-grow min-w-[calc(50%-1rem)] max-w-[calc(50%-1rem)]'>
          <div className='absolute right-0 z-10 select-none'>
            <Checkbox
              id="hasNoEndDate"
              name="hasNoEndDate"
              label="No End Date"
              checked={values?.hasNoEndDate}
              disabled={!!readOnly}
              readOnly={!!readOnly}
              onChange={handleChange}
            />
          </div>

          {!values?.hasNoEndDate && (
            <FastField
              label='END DATE'
              component={DateTimePicker}
              onlyDate
              name='endsAt'
              disabled={!!readOnly || values?.hasNoEndDate}
              value={!values?.hasNoEndDate ? new Date(values?.endsAt!) : undefined}
              error={touched?.endsAt && errors?.endsAt ? (errors?.endsAt as string) : ''}
              onChange={(newValue: Date) => {
                setFieldTouched!('endsAt', true);
                setFieldValue!('endsAt', newValue);
              }}
            />
          )}

          {values?.hasNoEndDate && (
            <FastField
              style={{ pointerEvents: 'none' }}
              component={TextInput}
              label='END DATE'
              disabled={true}
              value={'N/A'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildScheduleMessage;