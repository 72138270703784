
import { Button, ButtonVariantEnum, LoadingIndicator, ProgressBar } from '@Wonder-Cave/ui';
import { MessageTypeEnum, RecurringCampaignCooldownPeriodEnum, RecurringCampaignFrequencyEnum, RecurringCampaignStatusEnum } from '@shared/enums';
import { ICreateRecurringCampaignsRequest, IRecurringCampaign, IUpdateRecurringCampaignsRequest } from '@shared/models';
import { getCampaignEndDateForStartDate, getCampaignStartDate, getLocalDate, getOxfordCommaString } from '@shared/services';
import { convertFrequencyFormat, formatFrequencyFields } from '@shared/utils';
import { addDays } from 'date-fns';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { axiosGet, axiosPost, axiosPut } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useDomains from '../../hooks/useDomains';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import { CampaignTypeEnum } from '../shared/recurringcampaigns/campaign-type-enum';
import RecurringCampaignsCreateFooter from './RecurringCampaignsCreateFooter';
import RecurringCampaignsCreateHeader from './RecurringCampaignsCreateHeader';
import Build from './Steps/Build/Build';
import { recurringCampaignBuildFormSchema } from './Steps/Build/types';
import Plan from './Steps/Plan';
import Preview from './Steps/Preview/Preview';
import { recurringCampaignPreviewFormSchema } from './Steps/Preview/types';
import { IRecurringCampaignForm, recurringCampaignPlanFormSchema } from './types';

const RecurringCampaignsCreate = () => {
  const { id } = useParams<any>();
  const [sending, setSending] = useState(false);
  const [stepNumber, setStepNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [leads, setLeads] = useState(0);
  const [submissionError, setSubmissionError] = useState<string>();
  const startsAt = getCampaignStartDate();
  const { selectedClientId } = useClientContext();
  const [formErrors, setFormErrors] = useState<FormikErrors<IRecurringCampaignForm>>({});

  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();
  const domains = domainsData?.records ?? [];

  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsData?.records ?? [];

  const [formData, setFormData] = useState<IRecurringCampaignForm>({
    // Misc.
    status: RecurringCampaignStatusEnum.DRAFT,
    sendTestMessages: false,
    campaignType: CampaignTypeEnum.FUNDRAISING,
    frequencyCount: 1,
    clientId: selectedClientId,
    // Plan
    isMMSVideo: false,
    mediaUrl: '',
    showSuppressions: false,
    name: '',
    messageType: MessageTypeEnum.SMS,
    externalId: '',
    mediaFile: '',
    mediaName: '',
    audiences: [],
    suppressions: [],
    frontendInlineSuppression: { rules: [], disabled: false },
    systemNumberId: {
      label: '',
      value: '',
    },

    // Build
    url: '',
    domainId: '',
    clickTrackingEnabled: false,
    hasNoEndDate: false,
    message: '',
    startsAt: new Date(),
    endsAt: addDays(new Date(), 1),
    sendingStartsAt: startsAt,
    sendingEndsAt: getCampaignEndDateForStartDate(getLocalDate(), startsAt),
    frequency: RecurringCampaignFrequencyEnum.DAYS,
    cooldownPeriodCount: 1,
    cooldownPeriod: RecurringCampaignCooldownPeriodEnum.MONTHS,
    cooldownType: undefined

  });

  // leveraging this to ensure that the client id set in form data is matched with that pulled from client id context
  useEffect(() => {
    if (formData.clientId !== selectedClientId) {
      console.log('setting client id');
      setFormData({ ...formData, clientId: selectedClientId });
    }
  }, [selectedClientId]);

  const getScheduleMessage = (props: FormikProps<IRecurringCampaignForm>) => {
    const audiences = props?.values?.audiences ?? [];
    const sendingStartsAt = props?.values?.sendingStartsAt;
    const startsAt = props?.values?.startsAt;
    const endsAt = !props?.values?.hasNoEndDate ? props?.values?.endsAt : undefined;
    const hasNoEndDate = props?.values?.hasNoEndDate;

    if (isEmpty(audiences) || !sendingStartsAt || !startsAt) {
      return '';
    }

    const audienceNames = audiences?.map(audience => audience?.label) ?? [];
    const audienceNamesMessage = getOxfordCommaString(audienceNames);

    const startDate = new Date(startsAt).toLocaleDateString('en-US');
    const time = new Date(sendingStartsAt).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric'
    });

    if (endsAt && !hasNoEndDate) {
      const endDate = new Date(endsAt).toLocaleDateString('en-US');
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} ${props.values.frequencyCount === 1 ? convertFrequencyFormat(props.values.frequency ?? RecurringCampaignFrequencyEnum.DAYS) : `every ${formatFrequencyFields(props.values.frequencyCount, props.values.frequency ?? RecurringCampaignFrequencyEnum.DAYS)}`}, from ${startDate} to ${endDate}.`;
    } else {
      return `Your messages are scheduled to send to ${audienceNamesMessage} at ${time} ${props.values.frequencyCount === 1 ? convertFrequencyFormat(props.values.frequency ?? RecurringCampaignFrequencyEnum.DAYS) : `every ${formatFrequencyFields(props.values.frequencyCount, props.values.frequency ?? RecurringCampaignFrequencyEnum.DAYS)}`}, from ${startDate}.`;
    }
  };

  const stepConfigs = [
    {
      submit: () => { },
      schema: recurringCampaignPlanFormSchema,
    },
    {
      submit: () => { },
      schema: recurringCampaignBuildFormSchema,
    },
    {
      submit: () => { },
      schema: recurringCampaignPreviewFormSchema, // No schema for this one, nothing to validate
    },
  ];

  const saveDraft = async (formikProps) => {
    const recurringCampaignId = formikProps?.values?.id ?? id;

    try {
      const touched = {};
      Object.keys(formikProps.errors).forEach((k) => (touched[k] = true));
      formikProps.setTouched(touched, false); // The false here skips validation. We need to return the values
      if (Object.keys(await formikProps.validateForm()).length <= 0) {
        if (!recurringCampaignId) {
          const request: ICreateRecurringCampaignsRequest = {
            ...formikProps.values,
            name: formikProps.values.name.trim(),
            clientId: selectedClientId,
            hasNoEndDate: formikProps.values.hasNoEndDate,
            status: RecurringCampaignStatusEnum.DRAFT,
            audienceIds: formikProps.values.audiences.map(a => a.value),
            suppressionIds: formikProps.values.suppressions.map(s => s.value),
            inlineSuppressionFrontendFilter: formikProps?.values?.frontendInlineSuppression ?? null,
            inlineSuppressionBackendFilter: formikProps?.values?.frontendInlineSuppression?.rules?.length >= 1
              ? formatBackendMongoDBQuery(formikProps?.values?.frontendInlineSuppression, customDataPoints ?? [])
              : null,
            systemNumberId: formikProps.values.systemNumberId.value
          };
          try {
            const response = await axiosPost(`/recurring-campaigns/create`, request);
            if (response.status === 200 && response?.data) {
              return response;
            }
          } catch (e: any) {
            console.error('create campaign error:', e);

            const status = e.response?.status;
            const message = (status >= 400 && status < 500)
              ? (e.response?.data?.errors?.[0] ?? e.response?.data?.message ?? e.response?.data ?? e.message)
              : 'Internal Server Error. Failed to save campaign.';

            const lowerMessage = message.toLowerCase();
            if (stepNumber === 0 && lowerMessage.includes('campaign with name') && lowerMessage.includes('already exists')) {
              formikProps?.setTouched({
                name: true
              });
              await setFormErrors({
                name: message
              });
            } else {
              setSubmissionError(message);
            }
          }
        } else {
          const request: IUpdateRecurringCampaignsRequest = {
            ...formikProps.values,
            name: formikProps.values.name.trim(),
            clientId: formikProps.values.clientId,
            hasNoEndDate: formikProps.values.hasNoEndDate,
            status: RecurringCampaignStatusEnum.DRAFT,
            audienceIds: formikProps?.values?.audiences?.map(audience => audience.value) ?? [],
            suppressionIds: formikProps?.values?.suppressions?.map(suppression => suppression.value) ?? [],
            inlineSuppressionFrontendFilter: formikProps?.values?.frontendInlineSuppression ?? null,
            inlineSuppressionBackendFilter: formikProps?.values?.frontendInlineSuppression?.rules?.length >= 1
              ? formatBackendMongoDBQuery(formikProps?.values?.frontendInlineSuppression, customDataPoints ?? [])
              : null,
            systemNumberId: formikProps.values.systemNumberId.value
          };

          try {
            const response = await axiosPut(`/recurring-campaigns/${recurringCampaignId}`, request);
            if (response.status === 200 && response?.data) {
              return response;
            }
          } catch (e: any) {
            console.error('create campaign error:', e);

            const status = e.response?.status;
            const message = (status >= 400 && status < 500)
              ? (e.response?.data?.errors?.[0] ?? e.response?.data?.message ?? e.response?.data ?? e.message)
              : 'Internal Server Error. Failed to save campaign.';

            const lowerMessage = message.toLowerCase();
            if (stepNumber === 0 && lowerMessage.includes('campaign with name') && lowerMessage.includes('already exists')) {
              formikProps?.setTouched({
                name: true
              });
              await setFormErrors({
                name: message
              });
            } else {
              setSubmissionError(message);
            }
          }
        }
      }
    } catch (e: any) {
      console.error(e);

      const status = e.response?.status;
      const message = (status >= 400 && status < 500)
        ? (e.response?.data?.errors?.[0] ?? e.response?.data?.message ?? e.response?.data ?? e.message)
        : 'Internal Server Error. Failed to save campaign.';

      const lowerMessage = message.toLowerCase();
      if (stepNumber === 0 && lowerMessage.includes('a campaign with the name') && lowerMessage.includes('already exists')) {
        formikProps?.setTouched({
          name: true
        });
        await setFormErrors({
          name: message
        });
      }
    }
  };


  const Step = ({ stepNumber, formErrors, ...rest }) => {
    switch (stepNumber) {
      case 0:
        return <Plan values={formData} setLoading={setLoading} submissionError={submissionError} formErrors={formErrors} {...rest} />;
      case 1:
        return <Build values={formData} leads={leads} setLeads={setLeads} setLoading={setLoading} submissionError={submissionError} domains={domains} customDataPoints={customDataPoints} {...rest} />;
      case 2:
        return <Preview values={formData} leads={leads} setLeads={setLeads} saveDraft={saveDraft} submissionError={submissionError} setLoading={setLoading} sending={sending} setSending={setSending} customDataPoints={customDataPoints} {...rest} />;
      default:
        return <Plan values={formData} setLoading={setLoading} submissionError={submissionError} {...rest} />;
    }
  };

  const getRecurringCampaign = async (campaignId: string) => {
    try {
      setCampaignLoading(true);
      const response = await axiosGet(`/recurring-campaigns/${campaignId}`);
      const campaign: IRecurringCampaign = response?.data;
      const formattedStartDate = new Date(campaign.startsAt);
      const startsAt = formattedStartDate < new Date() ? getCampaignStartDate() : formattedStartDate;
      const endsAt = new Date(campaign.endsAt);
      setFormData({
        ...campaign,
        name: campaign?.name,
        client: { label: ' ', value: campaign.clientId },
        campaignType: CampaignTypeEnum.FUNDRAISING,
        showSuppressions: campaign?.frontendInlineSuppression?.rules?.length > 0,
        audiences: campaign?.audiences.map(a => ({ label: a.name, value: a.id })),
        suppressions: campaign?.suppressions.map(s => ({ label: s.name, value: s.id })),
        startsAt: startsAt,
        endsAt: endsAt,
        domainId: campaign?.domain,
        sendingStartsAt: campaign.sendingStartsAt,
        sendingEndsAt: endsAt,
        hasNoEndDate: campaign?.hasNoEndDate,
        // MMS set a dummy file for validation
        mediaFile: campaign.messageType === MessageTypeEnum.MMS ? new File([''], campaign.mediaName ?? 'filename.png', { type: 'image' }) : undefined,
        cooldownPeriodCount: campaign.cooldownPeriodCount,
        cooldownPeriod: campaign.cooldownPeriod,
        cooldownType: campaign.cooldownType,
        frequencyCount: campaign.frequencyCount,
        frequency: campaign.frequency,
        systemNumberId: campaign.systemNumberId
      });
    } catch (e) {
      console.log(e);
    } finally {
      setCampaignLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getRecurringCampaign(id);
    }
  }, [id]);

  return (
    <Formik
      initialValues={formData}
      validationSchema={stepConfigs[stepNumber].schema}
      // This onSubmit handler does nothing. 
      // We use separate functions to submit the form due to the complicated nature of multi-step forms and multiple submission avenues.
      onSubmit={stepConfigs[stepNumber].submit}
      initialStatus={true}
      enableReinitialize
    >
      {(props) => {
        return (
          <div className="flex flex-col h-full">
            <RecurringCampaignsCreateHeader formData={formData} stepNumber={stepNumber} formikProps={props} saveDraft={saveDraft} />
            <ProgressBar
              className="-mx-8"
              steps={[
                { num: 1, description: 'Plan', active: stepNumber === 0 },
                { num: 2, description: 'Build', active: stepNumber === 1 },
                { num: 3, description: 'Preview', active: stepNumber === 2 },
              ]}
            />
            <div className="relative mt-8 px-28">
              {campaignLoading && (
                <div
                  className="flex absolute z-20 w-[calc(100%+2rem)] h-[calc(100%+2rem)] -ml-32 -mt-8 backdrop-blur-[1.5px]"
                  aria-hidden="true"
                >
                  <div className="mx-auto mt-1/5">
                    <LoadingIndicator />
                  </div>
                </div>
              )}

              <form
                id="campaign-form"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              >
                <Step stepNumber={stepNumber} formErrors={formErrors} {...props} />

                <div className='flex flex-row justify-end w-full pb-24 mt-8'>
                  {stepNumber === 1 && (
                    <h3 className='mr-[4rem] w-full text-wc-blue'>
                      {getScheduleMessage(props)}
                    </h3>
                  )}

                  <Button
                    variant={ButtonVariantEnum.SECONDARY}
                    className="mr-4"
                    type="button"
                    onClick={() => (stepNumber === 0 ? history.back() : setStepNumber((prevState) => prevState - 1))}
                  >
                    BACK
                  </Button>
                  <RecurringCampaignsCreateFooter
                    customDataPoints={customDataPoints}
                    loading={loading}
                    sending={sending}
                    stepNumber={stepNumber}
                    formikProps={props}
                    setStepNumber={setStepNumber}
                    setFormErrors={setFormErrors}
                    setFormData={setFormData}
                    formErrors={formErrors}
                  />
                </div>
              </form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default RecurringCampaignsCreate;