import { Auth0Role, ITestMessageResponse } from '@shared/models';
import { StaticTableList } from '@Wonder-Cave/ui';
import useRoles from 'apps/a2p-portal/src/hooks/useRoles';
import { Radio } from '../Icons/Radio';
import { formatPhoneNumber } from '../numbers/number';


interface IMessageStatusProps {
  testMessage: ITestMessageResponse[];
}
const MessageStatus = ({ testMessage }: IMessageStatusProps) => {
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);

  return (
    <>
      {
        (testMessage?.length ?? 0) > 0 && (
          <div className="flex flex-wrap">
            <div className='flex items-center'>
              <h2 className="mr-2">Test Message Status  </h2>
              <Radio />
            </div>

            <StaticTableList
              className="basis-full text-xs mt-8"
              columns={[
                {
                  headerName: 'NUMBER',
                  fieldName: 'toNumber',
                  renderColumn: (row) => (
                    <div className="flex items-center whitespace-nowrap">
                      {formatPhoneNumber(row.toNumber)}
                      {row.errorCode === '-' && (
                        <div className="mx-2">
                          <Radio />
                        </div>
                      )}
                      {row.errorCode !== '-' && (
                        <div className="mx-2">
                        </div>
                      )}

                    </div>
                  ),
                },
                {
                  headerName: 'CARRIER',
                  fieldName: 'carrierName',
                  renderColumn: (row) => (
                    <div className="mr-2">
                      {(row.carrierName)}
                    </div>
                  ),
                },
                {
                  headerName: 'ERROR CODE',
                  fieldName: 'errorCode',
                },
                {
                  headerName: 'ERROR TYPE',
                  fieldName: 'errorType',
                  renderColumn: (row) => (
                    <div className="mr-2">
                      {(row.errorType)}
                    </div>
                  ),
                },
                ...(isSuperAdmin
                  ? [
                    {
                      headerName: 'PROVIDER ERROR CODE',
                      fieldName: 'providerErrorCode',
                    },
                    {
                      headerName: 'PROVIDER ERROR TYPE',
                      fieldName: 'providerErrorType',
                    },
                  ]
                  : []),
              ]}
              data={testMessage ?? []} />
          </div>
        )}
    </>
  );
};

export default MessageStatus;