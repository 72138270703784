import { A2P_ORG_NAME, Auth0ApplicationID, Auth0OrganizationID, Auth0OrganizationName } from '@shared/models';
import { getUserOrganizations, isPreProdSubdomain, PRE_PROD_ORG_NAMES } from '@shared/services';
import { LoadingIndicator } from '@Wonder-Cave/ui';
import { orderBy } from 'lodash';
import { useEffect } from 'react';
import { useMembership } from './contexts/MembershipContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import { Auth0FrontendConfig, getSubDomain } from './providers/auth0-data.provider';
import { clearCachedClient, getAuth0LocalStorage, getCachedSelectedOrgName, removeA2pSession } from './providers/auth0-storage.provider';
import { logout } from './providers/auth0.provider';

export interface IAppMainProps {
  targetOrgId: Auth0OrganizationID | undefined;
  targetOrgName: Auth0OrganizationName | undefined;
  setAuth0Config: (config: Auth0FrontendConfig) => void;
}

const AppMain = ({
  targetOrgId,
  targetOrgName,
  setAuth0Config,
}: IAppMainProps): JSX.Element => {
  const {
    isLoading: isAuth0Loading,
    isAuthenticated: isLoggedIn,
    user: auth0User,
    loginWithRedirect,
    logout: auth0Logout,
  } = useAuth0UserData();

  const { setOrganizations, setAuth0LocalStorage } = useMembership();

  const isLocal = window.location.origin.includes('localhost');

  const getMatchingOrganization = () => {
    const subDomain = getSubDomain();
    const isPreProd = isPreProdSubdomain(subDomain);
    const cachedSelectedOrgName = getCachedSelectedOrgName();

    const orgs = auth0User?.organizations
      ?.filter(org => org?.name?.toLowerCase()?.trim() !== A2P_ORG_NAME?.toLowerCase()?.trim())
      ?.filter(org => (isPreProd || isLocal) ? PRE_PROD_ORG_NAMES.includes(org?.name?.toLowerCase()?.trim()) : true)
      ?? [];

    const ordered = isLocal
      ? orderBy(orgs, org => org?.name === 'a2p_dev', 'desc')
      : orderBy(orgs, org => org?.name, 'asc');

    const match = ordered?.find(org => org?.id?.toLowerCase()?.trim() === targetOrgId?.toLowerCase()?.trim())
      ?? ordered?.find(org => org?.name?.toLowerCase()?.trim() === cachedSelectedOrgName?.toLowerCase()?.trim())
      ?? ordered?.find(org => org?.name?.toLowerCase()?.trim() === targetOrgName?.toLowerCase()?.trim())
      ?? ordered?.find(org => org?.name?.toLowerCase()?.trim() === subDomain?.toLowerCase()?.trim())
      ?? ordered?.[0];

    return match;
  };

  const getLocalApiUrl = (orgName: Auth0OrganizationName) => {
    if (['a2p_dev', 'a2p_dev2'].includes(orgName)) {
      return `http://localhost:3000/dev`;
    }

    if (['a2p_staging', 'a2p_staging2'].includes(orgName)) {
      return `http://localhost:3000/staging`;
    }

    return `http://localhost:3000/${orgName}`;
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const match = getMatchingOrganization();

    if (match) {
      const auth0Config: Auth0FrontendConfig = {
        label: match.display_name,
        organization: match.name as Auth0OrganizationName,
        organizationId: match?.id as Auth0OrganizationID,

        clientId: match.metadata.client_id as Auth0ApplicationID,
        apiUrl: isLocal ? getLocalApiUrl(match.name) : match.metadata.api_url,
        websocketUrl: match.metadata.websocket_url,
      };

      const auth0LocalStorage = getAuth0LocalStorage(auth0User);
      const a2pSession = auth0LocalStorage?.sessions?.find(session => session?.appId === Auth0ApplicationID.A2P);

      if (!a2pSession) {
        removeA2pSession();
        clearCachedClient(auth0Config, auth0User);
        logout({ auth0Config, auth0Logout });
      } else {
        setAuth0LocalStorage(auth0LocalStorage);
        setOrganizations(getUserOrganizations(auth0User));
        setAuth0Config(auth0Config);
      }
    }
  }, [targetOrgName, isLoggedIn, auth0User]);

  if (!isAuth0Loading && !isLoggedIn) {
    loginWithRedirect();
  }

  return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
};

export default AppMain;
