import { MessageTypeEnum } from '@shared/enums';
import { getCampaignEndDateForStartDate, getCampaignStartDate, getLocalDate } from '@shared/services';
import { Button, ButtonVariantEnum, LogoLoadingIndicator, ProgressBar } from '@Wonder-Cave/ui';
import { Formik, FormikErrors } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useClientContext } from '../../contexts/ClientContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import CampaignsCreateFooter from './CampaignCreateFooter';
import Build from './Steps/Build';
import Plan from './Steps/Plan';
import { campaignBuildFormSchema } from './Steps/types';
import { campaignPlanFormSchema, ICampaignForm } from './types';

const CreateCampaign = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [contactCount, setContactCount] = useState(0);
  const [stepNumber, setStepNumber] = useState(1);
  const [formErrors, setFormErrors] = useState<FormikErrors<ICampaignForm>>({});

  // Loading states
  const [saveExitLoading, setSaveExitLoading] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [submissionError, setSubmissionError] = useState<string>();

  const [{ data: customDataPointsData, loading: customDataPointsLoading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const customDataPoints = customDataPointsData?.records ?? [];
  const [loading, setLoading] = useState(false);

  const { selectedClientId } = useClientContext();
  const startsAt = getCampaignStartDate();

  const stepConfigs = [
    {
      submit: (props) => { },
      schema: campaignPlanFormSchema,
    },
    {
      submit: (props) => { },
      schema: campaignBuildFormSchema,
    },
    {
      submit: (props) => { },
      schema: {},
    },
  ];

  const [formData, setFormData] = useState<ICampaignForm>({
    // Misc.
    // status: CampaignStatusEnum.DRAFT,
    sendTestMessages: false,
    //frequencyCount: 1,
    clientId: selectedClientId,
    // Plan
    showSuppressions: false,
    isMMSVideo: false,
    mediaUrl: '',
    name: '',
    messageType: MessageTypeEnum.SMS,
    externalId: '',
    mediaFile: '',
    mediaName: '',
    audiences: [],
    suppressions: [],
    frontendInlineSuppression: { rules: [], disabled: false },
    backendInlineSuppression: { rules: [], disabled: false },
    systemNumberId: {
      label: '',
      value: '',
    },

    // Build
    url: '',
    //domainId: '',
    //clickTrackingEnabled: false,
    //hasNoEndDate: false,
    message: '',
    startsAt: startsAt,
    endsAt: getCampaignEndDateForStartDate(getLocalDate(), startsAt),
    //sendingStartsAt: startsAt,
    //sendingEndsAt: getCampaignEndDateForStartDate(getLocalDate(), startsAt),


  });

  useEffect(() => {
    if (formData.clientId !== selectedClientId) {
      console.log('setting client id');
      setFormData({ ...formData, clientId: selectedClientId });
    }
  }, [selectedClientId]);

  const Step = ({ stepNumber, formErrors, ...rest }) => {
    switch (stepNumber) {
      case 0:
        return <Plan values={formData} setLoading={setLoadingCampaign} submissionError={submissionError} formErrors={formErrors} {...rest} />;
      case 1:
        return <Build values={formData} contactCount={contactCount} onSubmit={stepConfigs[1].submit} {...rest} />;
      // case 2:
      //   return <Preview values={formData} leads={leads} setLeads={setLeads} saveDraft={saveDraft} submissionError={submissionError} setLoading={setLoading} sending={sending} setSending={setSending} customDataPoints={customDataPoints} {...rest} />;
      default:
        return <Plan values={formData} setLoading={setLoadingCampaign} submissionError={submissionError} {...rest} />;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between w-full mb-8 px-28">
        <h1>{name && stepNumber === 2 ? name : 'Create Campaign'}</h1>
        <Button isLoading={saveExitLoading} disabled={nextLoading || sending} type="submit" formId="campaign-form" variant={ButtonVariantEnum.TERTIARY}>
          SAVE DRAFT & EXIT
        </Button>
      </div>
      <ProgressBar
        className="-ml-4 w-[calc(100%+2rem)]"
        steps={[
          { num: 1, description: 'Plan', active: stepNumber === 0 },
          { num: 2, description: 'Build', active: stepNumber === 1 },
          { num: 3, description: 'Preview', active: stepNumber === 2 },
        ]}
      />
      <div className="relative mt-8 px-28">
        {loadingCampaign && (
          <div
            className="flex absolute z-20 w-[calc(100%+2rem)] h-[calc(100%+2rem)] -ml-32 -mt-8 backdrop-blur-[1.5px]"
            aria-hidden="true"
          >
            <div className="mx-auto mt-1/5">
              <LogoLoadingIndicator iris />
            </div>
          </div>
        )}
        <Formik
          initialValues={formData}
          validationSchema={stepConfigs[stepNumber].schema}
          onSubmit={stepConfigs[stepNumber].submit}
          enableReinitialize
          initialStatus={true}
        >
          {(props) => {
            return (
              <form
                id="campaign-form"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
              >
                <Step stepNumber={stepNumber} formErrors={formErrors} {...props} />
                <div className="flex justify-end w-full pb-2 mt-auto">
                  <Button
                    variant={ButtonVariantEnum.SECONDARY}
                    className="mr-4"
                    type="button"
                    onClick={() => (stepNumber === 0 ? navigate(-1) : setStepNumber((prevState) => prevState - 1))}
                  >
                    BACK
                  </Button>
                  <CampaignsCreateFooter
                    customDataPoints={customDataPoints}
                    loading={loading}
                    sending={sending}
                    stepNumber={stepNumber}
                    formikProps={props}
                    setStepNumber={setStepNumber}
                    setFormErrors={setFormErrors}
                    setFormData={setFormData}
                    formErrors={formErrors}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default CreateCampaign;