import { Auth0MembershipWithMeta, Auth0OrganizationData } from '@shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Auth0LocalStorage } from '../providers/auth0-storage.provider';

export interface IMembershipContext {
  membership: Auth0MembershipWithMeta | undefined;
  setMembership: Dispatch<SetStateAction<Auth0MembershipWithMeta | undefined>>;

  organizations: Auth0OrganizationData[] | undefined;
  setOrganizations: Dispatch<SetStateAction<Auth0OrganizationData[] | undefined>>;

  auth0LocalStorage: Auth0LocalStorage | undefined;
  setAuth0LocalStorage: Dispatch<SetStateAction<Auth0LocalStorage | undefined>>;

  isMembershipLoaded: boolean;
  setMembershipLoaded: Dispatch<SetStateAction<boolean>>;
}

const MembershipContext = React.createContext<IMembershipContext>({
  membership: undefined,
  setMembership: () => { },

  organizations: undefined,
  setOrganizations: () => { },

  auth0LocalStorage: undefined,
  setAuth0LocalStorage: () => { },

  isMembershipLoaded: false,
  setMembershipLoaded: () => { },
});

const MembershipContextProvider = MembershipContext.Provider;
const MembershipContextConsumer = MembershipContext.Consumer;
const useMembership = () => useContext(MembershipContext);
export { MembershipContext, MembershipContextConsumer, MembershipContextProvider, useMembership };

