import { Error } from '@Wonder-Cave/ui';
import useDomains from 'apps/a2p-portal/src/hooks/useDomains';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IDomainsDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const DomainsDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IDomainsDropdownProps): JSX.Element => {
  const [{ data: domainsData, loading: domainsLoading, error: domainsError }] = useDomains();

  const domains: IDropdownValue[] =
    domainsData?.records.map((domain) => ({
      label: domain.url ?? '',
      value: domain.url ?? '',
    })) ?? [];

  return (
    <>
      <Autocomplete
        label="Domain"
        value={domains.find((provider) => provider.value === value)}
        options={domains}
        onChange={onChange}
        onBlur={onBlur}
        showError={showError}
        error={errorMessage}
        disabled={disabled || (domains?.length ?? 0) <= 0}
      />
      {(domains?.length ?? 0) <= 0 && <div><div className='-mt-4'><Error color={"text-flame-orange"} text={"You don't have any active domains for click tracking."} /></div></div>}
    </>
  );
};

export default DomainsDropdown;
