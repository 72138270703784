import { MessageTypeEnum } from '@shared/enums';
import { checkSHAFTCompliance, getSMSMessageSegmentCount, isMoreThanTwoDays } from '@shared/services';
import * as yup from 'yup';

export interface ICampaignBuildForm {
  message: string;
}

export const campaignBuildFormSchema: yup.SchemaOf<ICampaignBuildForm> = yup.object().shape({
  message: yup
    .string()
    .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
    .when('messageType', {
      is: (messageType: MessageTypeEnum) => messageType === MessageTypeEnum.MMS,
      then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
      otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
    })
    .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
      return context.parent.clickTrackingEnabled && message ? message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
      return !context.parent.clickTrackingEnabled && message ? !message?.toLowerCase()?.includes('{{link}}') : true;
    })
    .test('shaftCompliance', 'Please update your message to remove banned words.', (message: string | undefined, context): boolean => {
      return checkSHAFTCompliance(message);
    })
    .test('segmentCount', 'Segment count must not be larger than 10', (message: string | undefined, context): boolean => {
      if (context.parent.messageType === MessageTypeEnum.SMS) {
        return getSMSMessageSegmentCount(message ?? '') <= 10;
      } else {
        return true;
      }
    })
    .defined('Required'),
  clickTrackingEnabled: yup.boolean(),
  twoWayEnabled: yup.boolean(),
  generateUniqueLinks: yup.boolean(),
  url: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().test('apostrophe', 'URL cannot have an apostrophe', (value) => !value?.includes('\'')).url('Invalid URL format').defined('Required'),
  }),
  domainId: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().defined('Required'),
  }),
  domain: yup.string().when('clickTrackingEnabled', {
    is: (clickTrackingEnabled: boolean) => clickTrackingEnabled,
    then: yup.string().defined('Required'),
  }),
  startsAt: yup
    .date()
    .min(new Date(), 'Campaign cannot start in the past')
    .typeError('Invalid Date')
    .defined('Required')
  ,
  endsAt: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required')
    .min(yup.ref('startsAt'), 'End date cannot be before Start date')
    .test('isMoreThanTwoDays', `Campaign must end within 2 business days of start time.`, (value, context): boolean => {
      return !isMoreThanTwoDays(context.parent.startsAt, context.parent.endsAt);
    })
});