import { Auth0Role } from '@shared/models';
import { useAuth0UserData } from 'apps/a2p-portal/src/hooks/useAuth0Custom';
import { Outlet } from 'react-router-dom';
import Roles from '../Roles';

export interface ProtectedRouteProps {
  roles?: Auth0Role[];
  [x: string]: any;
}

const ProtectedRoute = ({ roles }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0UserData();
  return ((isAuthenticated && roles) ?
    <Roles showPageNotFound roles={roles}>
      <Outlet />
    </Roles>
    : <Outlet />
  );
};

export { ProtectedRoute };

