import { FieldTypeEnum } from '@shared/enums';
import { extendObject } from '@shared/services';
import { Field, QueryBuilder, RuleGroupTypeIC } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.scss';
import { CustomAddGroupButton } from './CustomAddGroupButton';
import { CustomAddRuleButton } from './CustomAddRuleButton';
import { CustomCombinatorSelector } from './CustomCombinatorSelector';
import { CustomFieldSelector } from './CustomFieldSelector';
import { CustomOperatorSelector } from './CustomOperatorSelector';
import { CustomRemoveButton } from './CustomRemoveButton';
import { CustomValueEditor } from './CustomValueEditor';
import { DisabledCustomCombinatorSelector } from './DisabledCustomCombinatorSelector';
import './style.scss';
import { fieldTypeOperatorMap } from './types';

export interface INewQueryBuilderProps {
  fields?: Field[];
  query: RuleGroupTypeIC | undefined;
  setQuery: (query: RuleGroupTypeIC) => void;
  hideAddQuery?: boolean;
  maxRules?: number;
  disabled?: boolean;
}

export default ({
  fields,
  query,
  setQuery,
  maxRules,
  hideAddQuery,
  disabled,
}: INewQueryBuilderProps) => {
  return (
    <QueryBuilder
      fields={fields}
      query={extendObject(query, { disabled: !!disabled })}
      onQueryChange={(q) => setQuery(q)}
      independentCombinators
      showCombinatorsBetweenRules
      getOperators={(field) => {
        const match = fields?.find((f) => f.name === field);
        const fieldType = match?.inputType as FieldTypeEnum ?? FieldTypeEnum.STRING;
        return fieldTypeOperatorMap?.get(fieldType) ?? [];
      }}
      controlElements={{
        fieldSelector: (props) => CustomFieldSelector(props),
        operatorSelector: (props) => CustomOperatorSelector(props),
        combinatorSelector: (props) => props.disabled ? DisabledCustomCombinatorSelector(props) : CustomCombinatorSelector(props),
        valueEditor: (props) => CustomValueEditor(props),
        removeGroupAction: (props) => disabled ? <></> : CustomRemoveButton(props),
        removeRuleAction: props => disabled ? <></> : CustomRemoveButton(props),
        addGroupAction: (props) => (disabled || hideAddQuery) ? <></> : <CustomAddGroupButton query={query!} {...props} />,
        addRuleAction: (props) => disabled ? <></> : <CustomAddRuleButton maxRules={maxRules} {...props} />
      }}
    />
  );
};