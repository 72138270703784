import { FastField, Field, Formik } from 'formik';
import * as yup from 'yup';

import { KeywordTypeEnum } from '@shared/enums/keyword-type-enum';
import { IClientEntity, IKeywordEntity } from '@shared/models';
import { IDropdownValue } from '../shared/Form/Dropdown';
import SendingSystemNumberDropDown from '../shared/Form/Dropdowns/SendingSystemNumbersDropdown';
import { IRadioButtonOption, RadioButtonGroup } from '../shared/Form/RadioButton';
import { TextInput } from '../shared/Form/TextInput';
import { Keywords } from './Keywords';
import { IKeyword } from './types';

export interface IClientForm {
  name: string;
  isActive?: IRadioButtonOption;
  externalId?: string;
  tcrBrandId: string;
  senderIdentifier: string;
  messageFlow: string;
  welcomerSendingNumberId: IDropdownValue;
  stopKeywords?: IKeyword[];
  joinKeywords?: IKeyword[];
  helpKeywords?: IKeyword[];
}

const options: IRadioButtonOption[] = [
  {
    label: 'Enabled',
    value: true,
  },
  {
    label: 'Disabled',
    value: false,
  },
];

interface IClientFormProps {
  item: IClientEntity | undefined;
  onSubmit?: (formValues: IClientForm) => void;
  keywords: IKeywordEntity[];
  readOnly: boolean;
}

const ClientsForm = ({ item, onSubmit, keywords, readOnly }: IClientFormProps): JSX.Element => {
  const [stopKeywords, joinKeywords, helpKeywords]: IKeyword[][] = [[], [], []];
  keywords?.map((keyword) => {
    switch (keyword.type) {
      case KeywordTypeEnum.STOP:
        stopKeywords.push({
          id: keyword.id,
          word: keyword.word,
          reply: keyword.reply,
          type: keyword.type,
          isDefault: keyword.isDefault,
          existing: true
        });
        break;
      case KeywordTypeEnum.JOIN:
        joinKeywords.push({
          id: keyword.id,
          word: keyword.word,
          reply: keyword.reply,
          type: keyword.type,
          isDefault: keyword.isDefault,
          existing: true
        });
        break;
      case KeywordTypeEnum.HELP:
        helpKeywords.push({
          id: keyword.id,
          word: keyword.word,
          reply: keyword.reply,
          type: keyword.type,
          isDefault: keyword.isDefault,
          existing: true
        });
        break;
    }
  });
  const initialFormState: IClientForm = {
    name: item?.name ?? '',
    isActive:
      item?.isActive !== undefined && item?.isActive !== null
        ? options.find((option) => option.value === item?.isActive)
        : options[0],
    externalId: item?.externalId ?? '',
    tcrBrandId: item?.tcrBrandId ?? '',
    senderIdentifier: item?.senderIdentifier ?? '',
    messageFlow: item?.messageFlow ?? '',
    welcomerSendingNumberId:
    {
      label: item?.welcomerSystemNumberId ?? '',
      value: item?.welcomerSystemNumberId,
    },
    stopKeywords: stopKeywords,
    joinKeywords: joinKeywords,
    helpKeywords: helpKeywords,
  };

  const schema: yup.SchemaOf<IClientForm> = yup.object().shape({
    name: yup.string().defined('Required'),
    isActive: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.boolean().required('Required'),
      })
      .nullable(),
    externalId: yup.string(),
    tcrBrandId: yup.string().defined('Required'),
    senderIdentifier: yup.string().defined('Required'),
    messageFlow: yup.string().defined('Required'),
    welcomerSendingNumberId:
      yup.object().shape({
        label: yup.string().notRequired(),
        value: yup.mixed().notRequired(),
        additionalData: yup.mixed().notRequired(),
      }).notRequired(),
    stopKeywords: yup
      .object()
      .shape({
        reply: yup.string().defined('Required'),
      })
      .nullable(),
    joinKeywords: yup.array().of(
      yup.object()
        .shape({
          word: yup.string().defined('Word is required'),
          reply: yup.string().defined('Reply is required'),
        }).test({
          name: 'unique-word',
          test: (keyword, context) => {
            const otherKeywords = context.parent.filter(kw => kw.id !== keyword.id);
            //return true;
            return !otherKeywords.map(kw => kw.word).includes(keyword.word ?? '');
          },
          message: { word: 'Word must be unique.' }
        })
        .nullable()
    ),
    helpKeywords: yup
      .object()
      .shape({
        reply: yup.string().defined('Required'),
      })
      .nullable()
  });

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={async (values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work

          <>
            <form id="client-form" onSubmit={handleSubmit}>
              <div className="space-y-4 divide-y">
                <div className="flex flex-col space-y-4">
                  <TextInput
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    error={touched.name ? errors.name : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />

                  <Field
                    name="isActive"
                    component={RadioButtonGroup}
                    options={options}
                    label="Status"
                    error={touched.isActive && (errors.isActive as any)?.value}
                    value={values.isActive}
                    onBlur={handleBlur}
                    onChange={(isActive: IRadioButtonOption) => {
                      setFieldTouched('isActive');
                      setFieldValue('isActive', isActive);
                    }}
                    disabled={readOnly}
                  />
                  <FastField
                    component={TextInput}
                    id="externalId"
                    name="externalId"
                    label="Client ID"
                    value={values.externalId}
                    error={touched.externalId ? errors.externalId : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />
                  <FastField
                    component={TextInput}
                    id="tcrBrandId"
                    name="tcrBrandId"
                    label="Brand ID"
                    value={values.tcrBrandId}
                    error={touched.tcrBrandId ? errors.tcrBrandId : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />
                  <FastField
                    component={TextInput}
                    id="senderIdentifier"
                    name="senderIdentifier"
                    label="Sender Identifier"
                    value={values.senderIdentifier}
                    error={touched.senderIdentifier ? errors.senderIdentifier : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />
                  {item &&
                    <SendingSystemNumberDropDown
                      value={values.welcomerSendingNumberId}
                      onChange={(newValue) => {
                        setFieldValue('welcomerSendingNumberId', newValue);
                      }}
                      disabled={readOnly}
                      showError={!!touched.welcomerSendingNumberId}
                      errorMessage={errors.welcomerSendingNumberId?.value?.toString() ?? ''}
                      onBlur={() => {
                        setFieldTouched('welcomerSendingNumberId');
                      }}
                      clientId={item?.id}
                      label="Welcomer Default Sending Number" />
                  }
                  <FastField
                    component={TextInput}
                    id="messageFlow"
                    name="messageFlow"
                    label="Call to Action URL"
                    value={values.messageFlow}
                    error={touched.messageFlow ? errors.messageFlow : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={readOnly}
                  />
                  {/* {!!item?.id && (
                    <div className="flex flex-col space-y-4">
                      <FastField
                        component={TextInput}
                        id="welcomeMessage"
                        name="welcomeMessage"
                        label="Welcome Message"
                        value={values.welcomeMessage}
                        error={touched.welcomeMessage ? errors.welcomeMessage : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  )} */}
                </div>
                {!!item?.id && (
                  <div className="flex flex-col space-y-4">
                    <Keywords
                      stopKeywords={values.stopKeywords}
                      joinKeywords={values.joinKeywords}
                      helpKeywords={values.helpKeywords}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      touched={touched}
                      errors={errors}
                      readOnly={readOnly}
                    />
                  </div>
                )}
              </div>
            </form>
          </>
        )}
      </Formik >
    </>
  );
};

export default ClientsForm;
