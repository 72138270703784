import { useAuth0UserData } from '../hooks/useAuth0Custom';
import { Auth0FrontendConfig } from '../providers/auth0-data.provider';
import LoadingIndicator from './shared/LoadingIndicator';

interface ILoginProps {
  auth0Config: Auth0FrontendConfig | undefined;
}

const Login = ({ auth0Config }: ILoginProps): JSX.Element => {
  const { isAuthenticated: isLoggedIn, isLoading: isAuth0Loading, loginWithRedirect } = useAuth0UserData();

  if (isAuth0Loading) {
    return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
  }

  if (!isLoggedIn) {
    loginWithRedirect();
    return <></>;
  }

  return <></>;
};

export default Login;
