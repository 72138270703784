import { useAuth0UserData } from 'apps/a2p-portal/src/hooks/useAuth0Custom';
import { Link } from 'react-router-dom';

export interface NotFoundProps {
  title: string;
  showLink: boolean;
}

const NotFound = ({ showLink, title }: NotFoundProps): JSX.Element => {
  const { isAuthenticated } = useAuth0UserData();
  return (
    <div className="min-h-screen px-4 py-16 bg-gray-100 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-sky-600 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">{title}</h1>
              <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
            </div>
            {showLink && (
              <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to={isAuthenticated ? '/campaigns' : '/login'}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-sky-600 border border-transparent rounded-md shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
                >
                  Go back home
                </Link>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

const PageNotFound = (): JSX.Element => NotFound({ title: 'Page not found', showLink: true });
const AppNotFound = (): JSX.Element => NotFound({ title: 'Application not found', showLink: false });

export { AppNotFound, PageNotFound };

