import { Option, ValueSelectorProps } from 'react-querybuilder';
// and or dropdown

export const DisabledCustomCombinatorSelector = (props: ValueSelectorProps) => {
  const selectedOption = (props.options as Option[]).find((o) => o.name === props.value);
  return (
    <div className='mt-2 mb-2 text-dark-grey text-base'>
      {selectedOption?.name?.toUpperCase()}
    </div>
  );
};
